#app-header-login {
    background-image: url('/images/bg-candidate-login.png');
    background-size: cover;
    box-shadow:none;
    position: absolute;
}

#app-header-login-hiring-partner {
    background: transparent;
    background-size: cover;
    box-shadow:none;
    position: absolute;
}

.app-header {
    padding: 16px;

    &.rs-header {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 99;
        background-color: white;
        box-shadow: var(--rs-dropdown-shadow);
    }

    .login-btn {
        padding: 16px 32px;
        background: var(--rs-primary-500);
        border-radius: 48px;
        font-weight: 700;
    }

    .rs-navbar-brand {
        padding-top: 12px !important;
        padding-left: 12px !important;
    }

    .active-tab {
        color: #212121;
        font-weight: 700;
    }

    a {
        color: #212121;
    }

    .menu-desktop-view {
        display: block;
    }

    .menu-mobile-view,.menu-mobile-view-authorized {
        display: none;
    }

    .navbar-logo {
        height: 20px;
    }


    .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 600px !important;
        width: auto !important;
    }
    .notif-panel {
        border-radius: 20px;
        padding-bottom: 0px !important;
        
        .card-item {
            &:hover {
                background-color: #DAE8F8;
            }

            border-bottom: 0.25px solid #878787;

            &:last-child {
                border-bottom: none;
                border-bottom-left-radius: 20px !important;
                border-bottom-right-radius: 20px !important;
            }

            .card-btn {
                color: white;
                font-size: .8em !important;
                padding: 11px 16px;
                margin-top: 12px;

                &:hover,&:focus {
                    background-color: #003884;
                }
            }
        }

        .rs-dropdown-item-divider {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        .rs-dropdown-item-panel {
            padding: 16px !important;
            padding-bottom: 8px !important;
        }

        .mark-as-read {
            font-size: 0.8em !important;
            right: 5%;
            margin-top: 4px;
            color: #595959;
            float: right;
            text-decoration: none;
            margin-left: -10px;

            svg {
                margin-right: 6px;
            }

            &:hover {
                color: black;
            }
        }

        .no-hover:hover {
            background-color: white;
        }

        .no-hover {
            padding-bottom: 0px !important;
            padding-left: 0px !important;
            padding-right: 0px !important;

            .notif-panel-tab-title {
                text-decoration: none;
                color: #595959;
                font-weight: 400;
                font-style: bold;
                font-size: .9em !important;
                text-align: center;
                border-radius: 0px;

                &:hover {
                    background-color: #F7FBFF;
                }
            }
        }
        
        .notif-header-title {
            font-size: 1.3em;
        }

        .notif-noresult {
            width: 50%;
            margin-left: 25%;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        span {
            font-size: 0.8em;
            white-space: normal;
            line-height: 120%;
            color: #212121;
        }

        .star-btn {
            background-color: transparent;
            color: #FFB800;
        }

        .rs-avatar-image {
            min-width: 24px;
            min-height: 24px;
            object-fit: contain;
            background-color: white !important;
        }
    }

    .rs-navbar-item {
        svg {
            width: 1.4em;
            height: 1.4em;
        }
    }

    .rs-dropdown-item-with-icon {
        min-width: 230px;
        padding-left: 30px;
        padding-right: 30px;
        color: #212121 !important;

        &:hover {
            .rs-dropdown-item-menu-icon {
                background-color: #DAE8F8;
                margin-right: 10px;
            }
        }

        .rs-dropdown-item-menu-icon {
            background-color: white;
            margin-right: 10px;
        }

        svg {
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: 768px) {
    #app-header-login,#app-header-login-hiring-partner {
        position: fixed;
        background-color: white;
        background-image: none;
        box-shadow: var(--rs-dropdown-shadow);
    }
}

@media screen and (max-width: 425px) {
    .app-header {
        .menu-mobile-view {
            display: block;
            border-radius: 50%;
            background-color: rgb(228, 240, 255);

            .rs-navbar-item {
                width: 48px;
                height: 48px;

                svg {
                    margin-right: 0px !important;
                }
            }
        }

        .menu-mobile-view-authorized {
            display: block;
            border-radius: 50%;

            .rs-navbar-item {
                width: 48px;
                height: 48px;
                padding: 0px !important;

                .rs-avatar-image {
                    width: 48px;
                    height: 48px;
                    object-fit: contain;
                }

                svg {
                    margin-right: 0px !important;
                }
            }
        }

        .menu-desktop-view {
            display: none;
        }

        .rs-navbar-brand {
            padding-top: 2px !important;
        }

        .navbar-logo {
            height: 40px;
        }
    }

    #app-header-login,#app-header-login-hiring-partner {
        position: fixed;
        padding: 12px;
        width: 100%;
        top: 0;
        z-index: 2;
        background-color: white;
        background-image: none;
        box-shadow: var(--rs-dropdown-shadow);
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        p {
            margin-top: 12px;
        }

        label {
            text-align: start !important;
        }

        .rs-form {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        img.bg-change-pass {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }
    }
}

.changepass-modal {
    >.rs-modal-dialog {
        margin-top: 0px;

        >.rs-modal-content {
            margin-top: 0px;
        }
    }
}