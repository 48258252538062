@media (max-width: 1280px) and (min-width: 960px) {
  .search-results-row>.rs-col-lg-8 {
    width: 50%;
  }
  .search-results-row>.rs-col-lg-6 {
    width: 33.33%;
  }
  .rs-row.loader-container>.rs-col-lg-8,.rs-row.loader-container>.rs-col-lg-6 {
    width: 50%;
  }
}

img[alt="edit-logo"] {
  width: 2em !important;
  height: 2em !important;
  float: right;
  cursor: pointer;
}

.quill {
  background-color: white;
  color: #212121;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ql-container.ql-snow {
  border: none;
}

.app,.app-hp {
  min-height: 100vh;
  margin-top: 8vh;
  overflow-x: hidden;
  background-color: #ffffff;
}

.app-hp {
  margin-top: 0vh !important;
}

.app-hp .home-static-authorized-container {
  margin-top: 8vh !important;
}

.app-hp .rs-content {
  background-color: transparent;
}

body {
  min-height:100%;
}

.rs-header {
  z-index: 99;
}

h1,h2,h3 {
  color: black;  
}

h1 {
  font-size: 72px;
  line-height: 110%;
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 42px;
}

h5 {
  font-size: 1.35em;
}

a, p {
  font-size: 16px;
  line-height: 22.4px !important;
}

a:hover {
  color: #C2D4EB !important;
}

button {
  font-size: 16px !important;
}

label {
  font-size: 16px;
}

small {
  font-size: 14px;
}

span {
  font-size: 16px;
}

.icon-tg-eye-btn {
  margin-left: .5em;
}

.icon-tg-eye-btn.hide {
  margin-top: -.5em;
}

.p-tg-job-notactive, .small-tg-job-notactive {
  color: #b2b2b2
}

.btn-tg-primary {
  padding: 16px 32px;
  background: var(--rs-primary-500);
  border-radius: 48px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
}

.btn-tg-primary-hp,.btn-tg-primary-hp:hover,.btn-tg-primary-hp:focus {
  padding: 16px 32px;
  background: #70ACFF;
  border-radius: 48px;
  color: white;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
}

.btn-tg-google,.btn-tg-linkedin {
  padding: 16px 32px;
  border: 2px solid #004AAD;
  border-radius: 48px;
  color: #212121;

  background: transparent;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
}

.btn-tg-google img[alt="logo"],.btn-tg-linkedin img[alt="logo"] {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.btn-tg-google div, .btn-tg-linkedin div {
  display: inline-block;
  height: 26px;
  vertical-align: -webkit-baseline-middle;
}

.btn-tg-primary:disabled {
  background: #E4E4E4;
  color: #ABABAB;
  opacity: 1 !important;
}

.btn-tg-primary-hp:disabled {
  background: #E4E4E4;
  color: #ABABAB;
  opacity: 1 !important;
}

.btn-tg-primary:active {
  background: #003884;
}

.btn-tg-secondary {
  padding: 16px 32px;
  background: var(--rs-btn-default-bg);
  border-radius: 48px;
  font-weight: 500;
}

.btn-tg-tertiary,.btn-tg-tertiary:hover {
  padding: 16px 32px;
  border-radius: 48px;
  font-weight: 500;
  color: #212121;
}

.btn-tg-tertiary:hover {
  background-color: #E0E9F5;
}

.btn-tg-pill-shaped {
  margin-top: -10px;
  background: #E4F0FF;
  border-radius: 20px;
  padding: 10px 16px;
  white-space: pre-wrap;

  color: #003884;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: .8em;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.btn-tg-pill-shaped.success {
  margin-top: -10px;
  background: #05BE17;
  border-radius: 20px;
  padding: 10px 16px;

  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: .8em !important;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
}

.btn-tg-pill-shaped.failed {
  margin-top: -10px;
  background: #FA0000;
  border-radius: 20px;
  padding: 10px 16px;

  color: white;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: .8em !important;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
}

div.btn-tg-pill-shaped.success,div.btn-tg-pill-shaped.failed {
  cursor: default;
}

.rs-btn.btn-tg-pill-shaped.success:hover {
  background-color: rgb(44, 174, 85);
}

.rs-btn.btn-tg-pill-shaped.failed:hover {
  background-color: rgb(200, 32, 32);
}

.btn-tg-pill-shaped.waiting {
  margin-top: -10px;
  background: #FFE683;
  border-radius: 20px;
  padding: 10px 16px;

  color: #003884;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: .8em;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
}

.rs-checkbox-wrapper .rs-checkbox-inner:before {
  border: 1px solid #004AAD;
}

.rs-input {
  padding: 13px 16px;
  border: 1px solid #E3E5E6;
  border-radius: 8px;
}

.rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
  display: block;
  background: var(--rs-uploader-dnd-bg);
  border: 1px solid var(--rs-uploader-dnd-border);
}

.rs-input[type="text"]:not(:placeholder-shown):not(:disabled) {
  /* background-color: #F2F7FF !important; */
  color: #090A0A;
  /* border: 2px solid #2876C6 !important; */
  font-size: 1em;
}

.rs-input[type="text"] {
  min-height: 52px !important;
}

.rs-input[aria-invalid="true"] {
  border: 2px solid #FF5247 !important;
  border-radius: 8px;
}

.phone-prefix .rs-picker-toggle {
  min-height: 52px !important;
}

.rs-input[type="text"]:not(:placeholder-shown):not(:disabled):focus {
  border: 2px solid #004AAD !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.rs-input[type="password"]:not(:placeholder-shown):not(:disabled) {
  /* background-color: #F2F7FF !important; */
  color: var(--rs-picker-value);
  /* border: 2px solid #2876C6 !important; */
  font-size: 1em;
}

.rs-input:hover:not(:disabled) {
  border: 1px solid #004AAD;
}

.rs-input:focus {
  border: 2px solid #004AAD;
}

.rs-input[type="password"]:not(:placeholder-shown):not(:disabled):focus {
  border: 2px solid #004AAD !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.rs-input-group:not(.rs-input-group-disabled):hover, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:focus-within{
  border: none !important;
}

.rs-input-group:focus-within, .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .rs-input-group:not(.rs-input-group-disabled):hover{
  border: none !important;
}

.rs-input-group-addon {
  height: 100% !important;
}

.rs-picker {
  border-radius: 8px;
}

.rs-picker-select-menu-item-active, .rs-checkbox-checked>.rs-checkbox-checker>label{
  font-weight: 500 !important;
  color: #004AAD;
}

.rs-checkbox-checked>.rs-checkbox-checker>label{
  background-color: #F2F7FF;
}

.rs-picker-has-value .rs-picker-toggle {
  /* background-color: #F2F7FF !important; */
  border: 0.75px solid #E3E5E6;
}

.rs-picker-has-value.rs-picker-toggle-wrapper:not(:focus) {
  border: 0.75px solid #E3E5E6 !important;
  background-color: white !important;
}

.rs-picker-has-value.rs-picker-toggle-wrapper {
  background-color: transparent !important;
  border-color: #2876C6 !important;
}

.rs-picker-has-value.rs-picker-toggle-wrapper.rs-picker-focused {
  background-color: white !important;
  border: 2px solid #004AAD !important;
}

.rs-picker-has-value .rs-picker-toggle-value {
  font-size: 1em;
  color: #090A0A !important;
}

.rs-picker-has-value {
  background-color: #F2F7FF !important;
}

.rs-form-error-message {
  z-index: 6;
}

.rs-panel-body .rs-form-control-label {
  margin-bottom: 8px;
  font-size: 14px;
}

.rs-form-control .rs-btn-close {
  position: absolute !important;
  top: 25% !important;
  right: 5% !important;
}

.rs-tag .rs-btn-close {
  right: 0% !important;
  top: 0% !important;
}

.rs-panel-body .rs-form-group:nth-child(2) {
  margin-top: 1.5rem;
}

.rs-panel-body .rs-form-group:first-child {
  margin-top: 2.5rem;
}

.rs-panel-body .rs-input {
  font-weight: 400;
  border: 1px solid #E3E5E6;
}

.rs-panel-body .rs-input:focus {
  border: 2px solid #004AAD !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.rs-panel-body .rs-input::placeholder {
  color: #878787 !important;
}

.rs-panel-body .rs-input:disabled {
  background-color: #030C2F;
}

.rs-form-error-message-inner {
  font-weight: 200;
}

.rs-picker-input,.rs-picker-select {
  min-height: 46px;
}

.rs-picker-tag {
  min-height: 56px;
}

span.rs-picker-select-menu-item {
  font-size: .825rem;
}

.rs-picker-check-menu {
  max-height: 105px !important;
}

.rs-picker-check-menu .rs-checkbox-checker>label {
  font-size: .825rem;
}

.rs-picker-tag-wrapper {
  padding: 8px !important;
}

.rs-picker-toggle {
  height: 46px !important;
  padding: 13px 16px !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  font-size: 1em;   
}

/* Affix */
.tg-affix-side-panel {
  position: fixed;
  margin-top: 1rem;
}

.tg-affix-side-panel>.rs-stack {
  align-items: flex-start !important;
}

.tg-affix-side-panel>.rs-stack button {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 15px !important;
  font-size: .95rem !important;
}

/* Buttons */
.rs-btn-icon:hover {
  background-color: #E0E9F5;
  color: #004AAD;
}

.tg-affix-side-panel>.rs-stack button svg {
  margin-right: 16px !important;
}

.tg-btn-active,.tg-btn-active:hover {
  background-color: #E0E9F5;
  color: #004AAD;
}

/* Dashboard styles */
.dashboard-panel-blue {
  background-color: #D5E7FF;
  border-radius: 30px;

  padding-top: 4.5%;
  padding-bottom: 3.5%;
  padding-left: 2%;
  padding-right: 2%;
}

.rs-table-cell-header .rs-table-cell-content {
  background-color: #efefef;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .tg-affix-side-panel {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  h1 {
    font-size: 40px;
  }

  .tg-affix-side-panel {
    display: none;
  }

  h1, h2, h3 {
    line-height: 120%;
    margin-bottom: 1rem;
  }


  a, p {
    font-size: 0.925em;
  }

  button {
    font-size: 0.925em !important;
  }
  
  label {
    font-size: 0.85em;
  }
  
  small {
    font-size: 0.85em;
  }
  
  span {
    font-size: 0.85em;
  }
}

p.no-results-text {
  text-align: center;
  color: #595959;
}

/* Modal */
.rs-modal-content {
  background: #FFFFFF;
  border-radius: 30px;
}

/* .rs-modal-content .rs-modal-footer {
  text-align: center;
} */

.rs-modal-content .rs-modal-footer .rs-btn-subtle {
  margin-right: 16px;
  color: #212121;
  padding: 16px 32px;
  border-radius: 48px;
}

.rs-modal-content .rs-modal-footer .rs-btn-primary {
  padding: 16px 32px;
  background: var(--rs-primary-500);
  border-radius: 48px;
  font-weight: 500;
}

.rs-modal-content .rs-modal-body {
  padding: 16px 32px;
  margin-bottom: 1em;
}

.rs-modal-content h3 {
  /* Heading 2/Bold */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 130%;
  /* or 35px */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  /* Primary/Black */
  color: #212121;
}

.tg-checkbox-btn {
  background-color: white;
  color: #212121;
  padding:15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 25px;

  margin-bottom: 0.75rem;
}

.tg-checkbox-btn.rs-checkbox-checked {
  background-color: #004AAD;
}

.tg-checkbox-btn label {
  font-weight: 600;
  white-space: pre;
}

.tg-checkbox-btn>.rs-checkbox-checker {
  padding-left: 0px;
  padding-right: 20px;
}

.tg-checkbox-btn .rs-checkbox-wrapper {
  left: 90%;
}

.tg-checkbox-btn.rs-checkbox-checked>.rs-checkbox-checker>label {
  color: white !important;
  background-color: #004AAD !important;
}

.tg-checkbox-btn>.rs-checkbox-checker>label {
  color: #212121 !important;
  background-color: white;
  font-weight: 600 !important;
}

.tg-checkbox-group-btn {
  display: inline-block !important;
}

.tg-checkbox-btn .rs-checkbox-wrapper::after {
  border: solid black none !important;
  border-width: 0px 0px 0px 0px !important;
  transform: rotate(0deg) scale(1.2);
  content: '+';

  margin-left: 0.55em !important;
  margin-top: 0.6em !important;
  font-weight: 200 !important;
  width: 1em !important;
  height: 1em !important;
}

.tg-checkbox-btn.rs-checkbox-checked .rs-checkbox-wrapper::after {
  display: none;
}

.tg-checkbox-btn.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
  border: solid black none !important;
  border-width: 0px 0px 0px 0px !important;
  transform: rotateX(40deg) scale(1.25) !important;
  content: 'x';

  margin-left: 0px !important;
  margin-top: -0.1em !important;
  font-weight: 200 !important;
}

.bg-red {
  background-color: #FA0000 !important;
}

.color-red {
  color: #FA0000 !important;
}

.float-left {
  float: left !important;
}

/* General Test */
.generaltest-question {
  font-size: 20px;
  font-weight: 500;
}

.generaltest-answer {
  border: 1 solid #ABABAB !important;
  border-radius: 4px;
}

.full-width {
  width: 100%;
}

.generaltest-time {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 24px;
}

.cursor-pointer {
  cursor: pointer;
}

/* Margins */
.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.my-4 {
  margin-top: 1rem; /* 16px */
  margin-bottom: 1rem; /* 16px */
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mx-4 {
  margin-left: 1rem; /* 16px */
  margin-right: 1rem; /* 16px */
}

/* Text alignments */
.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}