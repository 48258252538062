.user-home-candidate-main-content {
    h1 {
        text-align: left !important;
        line-height: 2.2em;
        font-size: 2em;
        text-transform: capitalize;
    }

    >.rs-col {
        margin-bottom: 2.5em !important;
    }

    .rs-placeholder-graph {
        border-radius: 1.25em;
        margin-bottom: 1.5em;
        min-height: 24.375em;
    }

    .rs-placeholder-graph.loader-category {
        height: 7.5em !important;
        min-height: 20vh;
        max-height: 7.5em;
    }

    .search-results-row {
        margin-bottom: 1.5em;

        >.rs-col {
            padding-left: 0.75em;
            padding-right: 0.75em;
        }

        @media screen and (max-width: 48em) {
            margin-bottom: 0em;
            >.rs-col {
                margin-bottom: 1.5em;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
            object-fit: contain;
        }
    }

    .rs-input-group-addon,.rs-input-group-addon:hover {
        margin-top: 0.5625em;
        color: #3F77C2 !important;
        background-color: transparent !important;
    }

    .rs-input,.rs-picker-toggle,.rs-picker-tag {
        padding: 1.09375em 1.25em !important;
        padding-left: 2.25em !important;
        min-height: 3.375em;
    }

    .rs-picker-tag {
        min-height: 3.625em;
        color: #212121;
        padding: 0.5625em 1.25em !important;

        .rs-picker-toggle {
            min-height: max-content;
            padding: 0.5625em !important;
            padding-left: 1.09375em !important;
        }
    }

    .rs-picker-select,.rs-picker-tag {
        width: 100%;
    }

    .search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        border-radius: 1.25em;
        padding: 1em;
        max-height: 30em;
        transition: 0.4s;
    
        &:hover {
            box-shadow: 0 0.3125em 0.625em rgb(0 0 0 / 10%);
        }

        svg {
            color: var(--rs-primary-500);
        }

        p {
            color: #595959;
            line-height: 1.2em;
            font-size: 100%;
        }

        p.salary-range {
            color: #212121;
            line-height: 1.4em;
            font-weight: 700;
            font-size: 110%;
        }

        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;

                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }

            .rs-stack-item .industry-name {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .rs-col.company-info {
            p {
                margin-bottom: 0.3125em;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                overflow: hidden;
                display: -webkit-box;
                min-height: 47.98px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 3.125em;
            }
        }

        .rs-stack {
            width: 100%;
        }

        .rs-btn-icon {
            width: 100%;
            padding: 1em 0.5em;
        }

        .rs-btn-icon:disabled {
            background: #E4E4E4;
            color: #ABABAB;
            opacity: 0.7;
        }

        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }

        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;

                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }

        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }

        .rs-badge {
            border-radius: 1.25em;
            padding: 0.125em 0.625em;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        img.bg-transfer-job {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }
    }
}
