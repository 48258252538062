.hp-application-details-main-content {
    .rs-col-xs-24 {
        margin-top: 2em;
    }

    .rs-row:first-child {
        .rs-col-xs-24 {
            margin-top: 0px;
        }
    }

    a {
        color: #595959;
        span {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            span {
                color: #212121;
            }

            svg {
                color: #212121;
            }
        }
    }

    .hp-application-details-wrapper-card {
        border-radius: 20px 20px 0px 0px;

        div.card-header-wrapper {
            mask-size: 400px 327px;
            mask-image: -webkit-gradient(linear, center bottom, center top, 
            color-stop(0.00,  rgba(0,0,0,0)),
            color-stop(1.00,  rgba(0,0,0,1)));
            
            img {
                object-fit: cover;
                height: 327px;
            }
        }

        h2.job-company-name {
            color: #595959;
            opacity: 0.8;
            font-weight: 600;
            position: absolute;
            left: 4%;
            top: 290px;
            font-size: 1.3em;

            @media screen and (max-width: 425px) {
                left: 7%;
            }
        }

        h2.job-title {
            position: absolute;
            left: 4%;
            top: 320px;
            font-size: 1.5em;

            @media screen and (max-width: 425px) {
                left: 7%;
            }
        }

        .hp-application-details-header-btn {
            position: absolute;
            right: 4%;
            top: 310px;
        }

        @media screen and (max-width: 425px) {
            .hp-application-details-header-btn {
                display: none;
            }
        }

        .hp-application-details-card {
            background-color: #FFFFFF;
            color: #595959;
            border: 1px solid #E4E4E4;
            border-radius: 0px 0px 20px 20px;
            padding: 20px 10px;
            transition: 0.4s;

            .loading-skeleton>.rs-stack-item {
                width: 100%;
            }
    
            svg {
                color: #004AAD;
            }
    
            p {
                font-size: 1em;
            }
            
            p.job-requirements span, p.job-description span {
                span {
                    font-size: 1em !important;
                }
            }
    
            p.hp-application-details-title {
                color: #212121;
                font-weight: 700;
                font-size: 1.2em;
                margin-left: 8px;
                line-height: 25.2px;
            }
        }
    }

    .hp-application-details-company-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        transition: 0.4s;

        .rs-row>.rs-col {
            margin-bottom: 16px;
        }

        .rs-row>.rs-col:last-child {
            margin-bottom: 0px !important;
        }

        .rs-stack-item {
            width: 100%;
        }

        p {
            font-size: 1em;
        }

        .rs-col-xs-3 {
            svg {
                color: #004AAD;
                min-height: 14px;
                width: 14px;
            }
        }

        p.hp-application-details-company-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
        }

        p.hp-application-details-attribute-title {
            color: #212121;
            font-weight: 700;
            font-size: 1em;
            line-height: 25.2px;
        }
    }

    iframe {
        width: 100%;
        height: 75vh;
    }

    .rs-nav {
        padding: 10px;
        width: fit-content !important;
        background: #FFFFFF;
        border-radius: 30px;
        margin-bottom: 32px !important;

        @media screen and (max-width: 425px) {
            width: auto !important;

            a[role="button"] {
                width: 33%;
            }
        }

        .rs-nav-item {
            border-radius: 30px;
            color: black;
            font-weight: 600;
            padding: 10px 20px;
            line-height: 22.4px;
        }

        .rs-nav-item:focus {
            background-color: var(--rs-primary-500);
        }

        .rs-nav-item-active,.rs-nav-item:hover {
            color: white !important;
            background-color: var(--rs-primary-500);
        }
    }

    .applicant-details-panel {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 12px;

        .applicant-signed-offer-letter {
            background-color: #E4F0FF;
            border-radius: 8px;
            padding: 16px;
            margin-top: 1em;

            svg.download-icon,svg.file-icon {
                width: 1.5em;
                height: 1.5em;
                margin-top: .75em;
                float: right;
                cursor: pointer;
                color: #ABABAB;
            }

            svg.file-icon {
                float: left;
                color: #595959;
            }
        }

        .applicant-interview-btn {
            background: #E4F0FF;
            border-radius: 4px;
            color: #004AAD;
            font-weight: 600;
            font-size: 0.9em !important;

            svg {
                margin-bottom: -2px;
                margin-right: 4px;
            }

            &:disabled {
                color: #052349;
                background-color: #b1bbc7;
            }
        }

        .applicant-accept-btn {
            background: #05BE17;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            font-size: 0.9em !important;

            svg {
                margin-bottom: -2px;
                margin-right: 4px;
            }

            &:disabled {
                color: #ffffff8c;
                background-color: #047d10;
            }
        }

        .applicant-reject-btn {
            background: #FA0000;
            border-radius: 4px;
            color: white;
            font-weight: 600;
            font-size: 0.9em !important;

            svg {
                margin-bottom: -2px;
                margin-right: 4px;
            }

            &:disabled {
                color: #ffffff8c;
                background-color: #b90404;
            }
        }

        .social-media-logo {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            padding: 5px;
            cursor: pointer;
            background-color: #E4F0FF;
        }
    
        .social-media-logo:hover {
            background-color: #E4F0FF;
        }

        .rs-stack-item {
            width: 100%;

            font-size: 0.95em;

            p {
                font-weight: 600;
                color: #212121;
            }

            p.applicant-name {
                font-size: 1.2em;
            }
        }

        p {
            font-size: 1em;
        }

        p.applicant-details-attribute-content {
            font-weight: 200;
            margin-top: 0px !important;
        }

        .rs-col-xs-3 {
            svg {
                color: #004AAD;
                min-height: 48px;
                width: 14px;
            }
        }

        p.applicant-details-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
        }

        p.applicant-details-attribute-title {
            color: #212121;
            font-weight: 700;
            font-size: 1em;
            line-height: 25.2px;
        }
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        img.bg-interview-modal-hp {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }

        .upload-offer-section {
            padding-top: 1em;

            h5 {
                color: black;
                font-weight: 600;
                text-align: left;
                font-size: 1em;
            }

            p {
                color: black;
                font-size: .9em;
                text-align: justify;
            }
            
            p.description {
                color: var(--rs-text-primary);
                font-size: .8em;
                margin-bottom: 1em;

                >a {
                    font-size: 1em;
                }
            }
        }
    }
}