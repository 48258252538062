.hp-job-hiring-partner-main-content {
    h1 {
        text-align: left !important;
        line-height: 2.2em;
        font-size: 2em;
        text-transform: capitalize;
    }

    >.rs-col {
        margin-bottom: 2.5em !important;
    }

    .edit-job-btnlink {
        padding-left: 0px !important;
        font-size: 1em !important;
        margin-top: 8px;

        >img[alt="edit-logo"] {
            width: 1.25em !important;
            height: 1.25em !important;
            margin-left: 6px !important;
        }
    }

    a.back-btn {
        color: #595959;
        span {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            span {
                color: #212121;
            }

            svg {
                color: #212121;
            }
        }
    }

    .rs-placeholder-graph {
        border-radius: 1.25em;
        margin-bottom: 1.5em;
        min-height: 24.375em;
    }

    .rs-placeholder-graph.loader-category {
        height: 7.5em !important;
        min-height: 20vh;
        max-height: 7.5em;
    }

    .rs-panel.company-job-opening-card {
        margin-top: 24px !important;
        border-radius: 20px;

        .rs-panel {
            background-color: white !important;
        }
    }

    .learn-more-btn {
        color: #90BFFF !important;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        padding-left: 0px !important;
        text-align: left;

        >svg {
            margin-left: 4px;
            margin-top: 0px !important;
            color: #90BFFF !important;
            padding-top: 4px !important;
        }
    }

    .company-icon {
        text-align: center;
        display: contents;
        text-align: center !important;

        >.company-name {
            font-weight: 600;
            font-size: 1.25em !important;
            color: #212121;
            margin-bottom: 8px;
        }

        &:nth-child(n+1) {
            margin-top: 24px !important;
        }

        @media screen and (min-width: 784px) {
            &:nth-child(1) {
                margin-top: 0px !important;
            } 
            &:nth-child(2) {
                margin-top: 0px !important;
            } 
            &:nth-child(n+3) {
                margin-top: 36px !important;
            }   
        }
        
        @media screen and (min-width: 1024px) {
            &:nth-child(3) {
                margin-top: 0px !important;
            } 
            &:nth-child(1) {
                margin-top: 0px !important;
            } 
            &:nth-child(2) {
                margin-top: 0px !important;
            } 

            &:nth-child(n+4) {
                margin-top: 36px !important;
            }   
        }

        img[alt="company-logo"] {
            display: block;
            width: 5em;
            height: 5em;
            opacity: 1;
            margin: auto;
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%) !important;
            object-fit: contain;
            border-radius: 50%;
            
            margin-bottom: 16px;
        }
    }

    .hp-job-details-wrapper-card {
        border-radius: 20px 20px 0px 0px;

        .card-header-wrapper {
            mask-size: 400px 327px;
            mask-image: -webkit-gradient(linear, center bottom, center top, 
            color-stop(0.00,  rgba(0,0,0,0)),
            color-stop(1.00,  rgba(0,0,0,1)));
        }

        span {
            font-size: 1em;
        }

        img {
            object-fit: cover;
            height: 326px;
        }

        img[alt="logo-verified"] {
            width: 24px !important;
            height: 24px !important;
            margin-top: 8px;

            @media screen and (max-width: 425px) {
                margin-top: 0px;
            }
        }

        img[alt="company-logo"] {
            height: 84px;
            width: 84px;
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            object-fit: contain;
            border-radius: 50%;
            position: absolute;
            top: 284px;
            left: 5%;
            opacity: 1;
            background-color: white;
        }

        .rs-placeholder-graph.placeholder-logo {
            height: 84px !important;
            width: 84px !important;
            border-radius: 50%;
            position: absolute;
            top: 284px;
            left: 5%;
            opacity: 1;
            background-color: white;
        }

        h2.company-name {
            color: #595959;
            opacity: 0.8;
            font-weight: 600;
            position: absolute;
            left: 4%;
            top: 310px;
            font-size: 1.3em;
        }

        h2.company-title {
            position: absolute;
            left: 4%;
            top: 340px;
            font-size: 1.5em;
        }

        .company-details-header-btn {
            position: absolute;
            right: 4%;
            top: 320px;
        
        }

        div.header-company-details {
            position: absolute;
            top: 250px !important;

            >h3,>h5 {
                padding-left: 1.5rem !important;
                font-size: 1.85em;
                margin-top: .25em;
                color: #212121;
            }

            >h5 {
                color: #595959;
                font-size: 1.35em;
                margin-top: -2vh;
            }
        }

        @media screen and (max-width: 425px) {
            .company-details-header-btn {
                display: none;
            }
        }

        .hp-job-details-card {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }
    }

    .hp-job-details-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        transition: 0.4s;

        li {
            margin-bottom: 4px;
            font-size: 1em;
        }

        .cultures-icon {
            text-align: center;
    
            &:nth-child(n+1) {
                margin-top: 24px !important;
            }
    
            @media screen and (min-width: 784px) {
                &:nth-child(1) {
                    margin-top: 0px !important;
                } 
                &:nth-child(2) {
                    margin-top: 0px !important;
                } 
                &:nth-child(n+3) {
                    margin-top: 36px !important;
                }   
            }
            
            @media screen and (min-width: 1024px) {
                &:nth-child(3) {
                    margin-top: 0px !important;
                } 
                &:nth-child(1) {
                    margin-top: 0px !important;
                } 
                &:nth-child(2) {
                    margin-top: 0px !important;
                } 
    
                &:nth-child(n+4) {
                    margin-top: 36px !important;
                }   
            }
        }

        img[alt="company-culture-logo"] {
            display: block;
            width: 64px;
            height: 64px;
            opacity: 1;
            margin: auto;
            margin-bottom: 8px;
        }

        img[alt="company-culture-logo"]+b {
            font-size: 1.2em;
        }

        p.culture-text {
            margin-top: 8px !important;
            font-size: 1em !important;
        }

        .loading-skeleton>.rs-stack-item {
            width: 100%;
        }

        svg {
            color: #004AAD;
        }

        p {
            font-size: 1em;
        }

        p.hp-job-details-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
            margin-bottom: 16px;
        }

        h3.hp-job-details-title {
            font-size: 2em !important;
            margin-right: 8px;
        }

        svg {
            width: 14px;
            height: 14px;
            margin-top: 8px;

            @media screen and (max-width: 425px) {
                margin-top: -8px;
            }
        }
    }

    .hp-job-details-side-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        padding-bottom: 0px;
        transition: 0.4s;
        width: 100%;

        @media screen and (max-width: 768px) {
            margin-bottom: 1rem;
        }

        .rs-row>.rs-col {
            margin-bottom: 16px;
        }

        .rs-row>.rs-col:last-child {
            margin-bottom: 0px !important;
        }

        .rs-stack-item {
            width: 100%;
        }

        p, a, span {
            font-size: .9em !important;
        }

        .rs-col-xs-3 {
            svg {
                color: #004AAD;
                min-height: 48px;
            }
        }

        p.hp-job-details-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
            margin-bottom: 8px;
        }

        p.hp-job-details-attribute-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.05em !important;
            line-height: 25.2px;
        }

        p.hp-job-details-attribute-title+a {
            overflow-wrap: break-word;
        }
    }

    .search-results-row {
        margin-bottom: 1.5em;

        >.rs-col {
            padding-left: 0.75em;
            padding-right: 0.75em;
        }

        @media screen and (max-width: 48em) {
            margin-bottom: 0em;
            >.rs-col {
                margin-bottom: 1.5em;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
            object-fit: contain;
        }
    }

    .hp-job-details-search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        cursor: pointer;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
        min-height: 340px;
        margin-top: 24px;
        transition: 0.4s;
        border-radius: 20px !important;
    
        @media screen and (max-width: 425px) {
            height: 372px;
        }
    
        border: 1px solid #E4E4E4;
        border-radius: 20px;
    
        img[alt="card-header"] {
            min-height: 165px;
            object-fit: cover;
        }
    
        img[alt="company-logo"] {
            height: 48px;
            width: 48px;
            object-fit: contain;
            border-radius: 50%;
            position: absolute;
            top: 45%;
            left: 7.5%;
            background-color: white;
        }
    
        img[alt="logo-startup"] {
            object-fit: contain !important;
            background-color: white;
        }
    
        svg {
            margin-top: 0px !important;
        }
    
        &:hover {
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
        }
    
        svg {
            color: var(--rs-primary-500);
        }
    
        p {
            color: #595959;
            line-height: 19.2px;
            font-size: 100%;
        }
    
        p.salary-range {
            color: #212121;
            line-height: 22.4px;
            font-weight: 700;
            font-size: 110%;
        }
    
        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;
    
                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }
        }
    
        .rs-col.company-info {
            p {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            
            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    
        .rs-col.company-info:last-child {
            p {
                margin-top: 8px;
                font-weight: 400;
                line-height: 140%;
                /* Black / 90 */
                color: #595959;
    
            }
        }
    
        .rs-stack {
            width: 100%;
        }
    
        .rs-btn-icon {
            width: 100%;
            padding: 16px 8px;
        }
    
        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }
    
        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;
    
                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }
    
        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }
    
        .rs-badge {
            border-radius: 20px;
            padding: 2px 10px;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }
    
        .jobs-count {
            >b {
                line-height: 140%;
                font-weight: 600;
                color: #212121;
            } 
        }
    }

    .add-new-job {
        margin-top: 8px;

        @media screen and (min-width: 1024px) {
            svg {
                padding-top: 4px;
            } 
        }
    }

    .rs-input-group-addon,.rs-input-group-addon:hover {
        margin-top: 0.5625em;
        color: #3F77C2 !important;
        background-color: transparent !important;
    }

    .rs-input,.rs-picker-toggle,.rs-picker-tag {
        padding: 1.09375em 1.25em !important;
        padding-left: 2.25em !important;
        min-height: 3.375em;
    }

    .rs-picker-tag {
        min-height: 3.625em;
        color: #212121;
        padding: 0.5625em 1.25em !important;

        .rs-picker-toggle {
            min-height: max-content;
            padding: 0.5625em !important;
            padding-left: 1.09375em !important;
        }
    }

    .rs-picker-select,.rs-picker-tag {
        width: 100%;
    }

    .loading-skeleton>.rs-stack-item {
        width: 100%;
    }

    .myoverview-panel {
        color: white;
        border-radius: 20px;
        min-height: 162px;
        transition: 0.4s;

        &:hover {
            box-shadow: 0 0.3125em 0.625em rgb(0 0 0 / 10%);
        }

        p {
            font-weight: 600;
            font-size: 0.5em !important;
            min-height: 44.8px;
        }

        b {
            font-size: 1.25em;
        }

        small {
            color: white;
            display: block;
            font-size: 0.45em;
        }
    }

    .job-list-panel {
        background-color: #ffffff;
        border: 1px solid #E4E4E4;
        border-radius: 20px;

        h2 {
            font-size: 1.35em;
        }

        .rs-stack-item {
            width: 100% !important;
        }

        .job-list-search-results {
            background: white;
            border-radius: 12px;
            color: #212121;
            cursor: pointer;

            p {
                font-size: 0.9em;
                font-weight: 600;
            }

            small {
                font-size: 0.8em;
            }

            &:hover,&.active {
                color: #004AAD;
                background: #F2F7FF;
            }
        }
    }

    @media screen and (max-width: 425px) {
        .myoverview-panel#accepted-phase {
            margin-top: 16px !important;
        }   

        .myoverview-panel#interview-phase {
            margin-top: 16px !important;
        }   

        .myoverview-panel#rejected-phase {
            margin-top: 16px !important;
        }   

    }

    .myoverview-panel#job-applied{
        background-color: #DC67FF;
    }

    .myoverview-panel#screening-phase{
        background-color: #FFB800;
    }

    .myoverview-panel#need-proceed-phase {
        background-color: #71ACFF;
    }
    
    .myoverview-panel#interview-phase {
        background-color: #003884;
    }

    .myoverview-panel#accepted-phase {
        background-color: #05BE17;
    }

    .myoverview-panel#rejected-phase {
        background-color: #FA0000;
    }

    .search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        border-radius: 1.25em;
        padding: 1em;
        max-height: 30em;
        transition: 0.4s;
    
        &:hover {
            box-shadow: 0 0.3125em 0.625em rgb(0 0 0 / 10%);
        }

        svg {
            color: var(--rs-primary-500);
        }

        p {
            color: #595959;
            line-height: 1.2em;
            font-size: 100%;
        }

        p.salary-range {
            color: #212121;
            line-height: 1.4em;
            font-weight: 700;
            font-size: 110%;
        }

        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;

                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }
        }

        .rs-col.company-info {
            p {
                margin-bottom: 0.3125em;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 3.125em;
            }
        }

        .rs-stack {
            width: 100%;
        }

        .rs-btn-icon {
            width: 100%;
            padding: 1em 0.5em;
        }

        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }

        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;

                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }

        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }

        .rs-badge {
            border-radius: 1.25em;
            padding: 0.125em 0.625em;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }
    }

    .action-btn-group {
        margin-top: -8px;

        .action-btn {
            background-color: transparent;
            border: 1px solid #E4E4E4;
            border-radius: 4px;

            &.comments {
                color: #004AAD;
            }

            &.check {
                color: #05BE17;
            }

            &.times {
                color: #FA0000;
            }

            &:hover {
                background-color: #E4E4E4;
            }
        }
    }

    // Table css
    .rs-table-body-row-wrapper {
        background-color: white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    
    .rs-table-cell-header>div[role="columnheader"] {
    
        .rs-table-cell-content {
            text-align: left !important;
            font-size: 1.25em;
            color: #212121;
            font-weight: 600;
        }
    }
    
    .rs-table-row {
        text-align: center;
    
        .rs-table-cell-content {
            color: #212121;
            padding: 28px 18px;

            p {
                background-color: #90BFFF;
                white-space: break-spaces;
                margin-top: -16px;
                padding: 6px;
                font-size: 1em;
                color: #004AAD;
                background: #F2F7FF;
                border-radius: 4px !important;
            }
        }
    
        .rs-avatar {
            background-color: white;
            object-fit: contain;
        }
    
        .rs-table-cell-last {
            .rs-table-cell-content {
                padding-right: 20%;
            }
        }
    
        &[role="row"] {
            cursor: pointer;
        }
    
        &[role="row"].rs-table-row-header {
            cursor: default;
        }
    }

    .rs-pagination-group {
        padding-top: 24px;

        .rs-pagination-group-total,.rs-pagination-btn {
            color: #595959;
            
            span {
                font-size: 1em;
            }
        }

        .rs-pagination {
            button {
                font-size: 1em !important;
            }
        }
    }
}

.hp-modal {
    .rs-modal-content {
        margin-top: 0px !important;
    
        .rs-checkbox-group {
            margin-top: 8px;
        }

        .rs-radio label {
            font-size: 1em !important;
        }
    
        .tg-checkbox-group-btn {
            margin-top: 12px;
            
            .tg-checkbox-btn {
                border: 1px solid #004AAD;
                border-radius: 48px;
        
                .rs-checkbox-wrapper .rs-checkbox-inner:before {
                    border: 1px solid white !important;
                }
            }
        }
    
        .rs-modal-body {
            text-align: left;
    
            .rs-uploader-text, .rs-uploader-picture-text, .rs-uploader-picture {
                display: block;
    
                .rs-uploader-trigger-btn {
                    cursor: default;
                }
    
                svg {
                    width: 1.75em;
                    color: #757575;
                    height: 1.75em;
                    margin-top: 1.5em;
    
                    &.download-icon,&.pencil-edit-icon {
                        width: 1.4em;
                        height: 1.4em;
                        cursor: pointer;
                        color: #ABABAB;
                    }
                }
            }

            .rs-uploader-picture-text .rs-uploader-file-item-btn-remove {
                top: 0 !important;
            }
    
            .rs-uploader-trigger-customize {
                width: 100%;
    
                .rs-uploader-trigger-btn {
                    width: 100%;
                }
            }
    
            .rs-form-control-label {
                font-size: 1em;
                font-weight: 600;
            }
    
            .rs-col-xs-8 {
                padding-top: 12px !important;
            }
    
            .quill {
                margin-top: 8px;
    
                p {
                    text-align: left !important;
                }
            }
    
            img.bg-interview-modal-hp {
                max-height: 250px;
                margin-bottom: 1.3rem;
            }
        }
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        img.bg-interview-modal-hp {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }
    }
}