.company-details-main-content {
    .rs-col-xs-24 {
        margin-top: 2em;
    }

    .rs-row:first-child {
        .rs-col-xs-24 {
            margin-top: 0px;
        }
    }

    a {
        color: #595959;
        span {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            span {
                color: #212121;
            }

            svg {
                color: #212121;
            }
        }
    }

    .cultures-icon {
        text-align: center;

        &:nth-child(n+1) {
            margin-top: 24px !important;
        }

        @media screen and (min-width: 784px) {
            &:nth-child(1) {
                margin-top: 0px !important;
            } 
            &:nth-child(2) {
                margin-top: 0px !important;
            } 
            &:nth-child(n+3) {
                margin-top: 36px !important;
            }   
        }
        
        @media screen and (min-width: 1024px) {
            &:nth-child(3) {
                margin-top: 0px !important;
            } 
            &:nth-child(1) {
                margin-top: 0px !important;
            } 
            &:nth-child(2) {
                margin-top: 0px !important;
            } 

            &:nth-child(n+4) {
                margin-top: 36px !important;
            }   
        }
    }

    .company-details-wrapper-card {
        border-radius: 20px 20px 0px 0px;

        span {
            font-size: 1em;
        }

        img {
            object-fit: cover;
            height: 326px;
        }

        img[alt="logo-verified"] {
            width: 24px !important;
            height: 24px !important;
            margin-top: 8px;

            @media screen and (max-width: 425px) {
                margin-top: 0px;
            }
        }

        img[alt="company-logo"] {
            height: 84px;
            width: 84px;
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            object-fit: contain;
            border-radius: 50%;
            position: absolute;
            top: 284px;
            left: 5%;
            opacity: 1;
            background-color: white;
        }

        .rs-placeholder-graph.placeholder-logo {
            height: 84px !important;
            width: 84px !important;
            border-radius: 50%;
            position: absolute;
            top: 284px;
            left: 5%;
            opacity: 1;
            background-color: white;
        }

        h2.company-name {
            color: #595959;
            opacity: 0.8;
            font-weight: 600;
            position: absolute;
            left: 4%;
            top: 310px;
            font-size: 1.3em;
        }

        h2.company-title {
            position: absolute;
            left: 4%;
            top: 340px;
            font-size: 1.5em;
        }

        .company-details-header-btn {
            position: absolute;
            right: 4%;
            top: 320px;
        }

        @media screen and (max-width: 425px) {
            .company-details-header-btn {
                display: none;
            }
        }
    }

    .company-details-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 0px 0px 20px 20px;
        padding: 20px 10px;
        transition: 0.4s;

        img[alt="company-culture-logo"] {
            display: block;
            width: 64px;
            height: 64px;
            opacity: 1;
            margin: auto;
            margin-bottom: 8px;
        }

        img[alt="company-culture-logo"]+b {
            font-size: 1.2em;
        }

        p.culture-text {
            margin-top: 8px !important;
            font-size: 1em !important;
        }

        .loading-skeleton>.rs-stack-item {
            width: 100%;
        }

        svg {
            color: #004AAD;
        }

        p {
            font-size: 1em;
        }

        p.company-details-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
            margin-bottom: 8px;
        }

        h3.company-details-title {
            font-size: 2em;
            margin-right: 8px;
        }

        svg {
            width: 14px;
            height: 14px;
            margin-top: 8px;

            @media screen and (max-width: 425px) {
                margin-top: -8px;
            }
        }
    }

    .rs-panel.company-job-opening-card {
        margin-top: 24px !important;
        border-radius: 20px;

        .rs-panel {
            background-color: white !important;
        }
    }

    .company-details-side-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        padding-bottom: 0px;
        transition: 0.4s;
        width: 100%;

        .rs-row>.rs-col {
            margin-bottom: 16px;
        }

        .rs-row>.rs-col:last-child {
            margin-bottom: 0px !important;
        }

        .rs-stack-item {
            width: 100%;
        }

        p, a {
            font-size: 1em;
        }

        .rs-col-xs-3 {
            svg {
                color: #004AAD;
                min-height: 48px;
            }
        }

        p.company-details-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
        }

        p.company-details-attribute-title {
            color: #212121;
            font-weight: 700;
            font-size: 1em;
            line-height: 25.2px;
        }

        p.company-details-attribute-title+a {
            overflow-wrap: break-word;
        }
    }
}

.company-details-search-result-card {
    background-color: #FFFFFF;
    color: #E4E4E4;
    cursor: pointer;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    min-height: 400px;
    margin-top: 24px;
    transition: 0.4s;
    border-radius: 20px !important;

    @media screen and (max-width: 425px) {
        height: 372px;
    }

    border: 1px solid #E4E4E4;
    border-radius: 20px;

    img[alt="card-header"] {
        min-height: 165px;
        object-fit: cover;
    }

    img[alt="company-logo"] {
        height: 48px;
        width: 48px;
        object-fit: contain;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 7.5%;
        background-color: white;
    }

    img[alt="logo-startup"] {
        object-fit: contain !important;
        background-color: white;
    }

    svg {
        margin-top: 0px !important;
    }

    &:hover {
        box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    }

    svg {
        color: var(--rs-primary-500);
    }

    p {
        color: #595959;
        line-height: 19.2px;
        font-size: 100%;
    }

    p.salary-range {
        color: #212121;
        line-height: 22.4px;
        font-weight: 700;
        font-size: 110%;
    }

    .rs-stack {
        >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
            width: 100% !important;

            .rs-stack,.rs-stack-item {
                width: 100% !important;
            }
        }
    }

    .rs-col.company-info {
        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        
        b {
            font-size: 1.2em;
            color: black;
            font-weight: 700;
            overflow: hidden;
            display: -webkit-box;
            min-height: 47.98px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .rs-col.company-info:last-child {
        p {
            margin-top: 8px;
            font-weight: 400;
            line-height: 140%;
            /* Black / 90 */
            color: #595959;

        }
    }

    .rs-stack {
        width: 100%;
    }

    .rs-btn-icon {
        width: 100%;
        padding: 16px 8px;
    }

    .rs-stack-item .rs-stack-item:first-child {
        .rs-badge {
            background-color: #E9A0FF;
        }
    }

    .rs-stack-item .rs-stack-item:nth-child(2) {
        .rs-badge {
            background-color: #FFDE59;

            &.rs-badge-fulltime {
                background-color: #003884 !important;
                color: #ffffff !important;
            }
        }
    }

    .rs-stack-item .rs-stack-item:last-child {
        .rs-badge {
            background-color: #90BFFF;
        }
    }

    .rs-badge {
        border-radius: 20px;
        padding: 2px 10px;
        font-weight: 400;
        white-space: nowrap;
        color: #212121;
    }

    .jobs-count {
        >b {
            line-height: 140%;
            font-weight: 600;
            color: #212121;
        } 
    }
}

.company-details-search-result-card.similar-company-card{
    max-height: 375px !important;
    min-height: unset !important;

    .rs-col.company-info, .rs-col.company-info>b {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
    }
}

#job-openings{
    cursor: default !important;
}