.rs-table-body-row-wrapper {
    background-color: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.rs-table-cell-header>div[role="columnheader"] {

    .rs-table-cell-content {
        text-align: center;
        font-size: .9rem;
        color: #595959;
    }
}

.rs-table-row-header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.rs-table-row {
    text-align: center;

    .rs-table-cell-content {
        color: #212121;
        padding: 28px 10px;
        font-size: .8rem;

        div.btn-tg-pill-shaped {
            font-size: .9em !important;
        }
    }

    .rs-avatar {
        background-color: white;
        object-fit: contain;
    }

    .rs-table-cell-last {
        .rs-table-cell-content {
            padding-right: 20%;
        }
    }

    &[role="row"] {
        cursor: pointer;
    }

    &[role="row"].rs-table-row-header {
        cursor: default;
    }
}

.myapplication-container {
    .rs-avatar>.rs-avatar-image {
        object-fit: contain;
    }

    h2 {
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.25em;
    }

    .rs-pagination-group {
        padding-top: 24px;

        .rs-pagination-group-total,.rs-pagination-btn {
            color: #595959;
            font-size: .85rem !important;
            span { font-size: .85rem; }
        }
    }

    .rs-nav {
        padding: 10px;
        width: fit-content !important;
        background: #FFFFFF;
        border-radius: 30px;
        margin-bottom: 32px !important;

        @media screen and (max-width: 425px) {
            width: auto !important;

            a[role="button"] {
                width: 33%;
            }
        }

        .rs-nav-item {
            border-radius: 30px;
            color: black;
            font-weight: 600;
            font-size: .9rem;
            padding: 10px 20px;
            line-height: 22.4px;
        }

        .rs-nav-item:focus {
            background-color: var(--rs-primary-500);
        }

        .rs-nav-item-active,.rs-nav-item:hover {
            color: white !important;
            background-color: var(--rs-primary-500);
        }
    }

    .saved-job-main-content {
        >.rs-col {
            margin-bottom: 40px !important;
        }
    
        .rs-placeholder-graph {
            border-radius: 20px;
            margin-bottom: 24px;
            min-height: 390px;
        }
    
        .rs-placeholder-graph.loader-category {
            height: 120px !important;
            min-height: 20vh;
            max-height: 120px;
        }
    
        .search-results-row {
            margin-bottom: 24px;
    
            >.rs-col {
                padding-left: 12px;
                padding-right: 12px;
            }
    
            @media screen and (max-width: 768px) {
                margin-bottom: 0px;
                >.rs-col {
                    margin-bottom: 24px;
                }
            }
    
            .rs-avatar-image {
                object-fit: contain;
                background-color: white;
                object-fit: contain;
            }
        }
    
        .rs-input-group-addon,.rs-input-group-addon:hover {
            margin-top: 9px;
            color: #3F77C2 !important;
            background-color: transparent !important;
        }
    
        .rs-input,.rs-picker-toggle,.rs-picker-tag {
            padding: 17.5px 20px !important;
            padding-left: 36px !important;
            min-height: 54px;
        }
    
        .rs-picker-tag {
            min-height: 58px;
            color: #212121;
            padding: 9px 20px !important;
    
            .rs-picker-toggle {
                min-height: max-content;
                padding: 9px !important;
                padding-left: 17.5px !important;
            }
        }
    
        .rs-picker-select,.rs-picker-tag {
            width: 100%;
        }
    
        .search-result-card {
            background-color: #FFFFFF;
            color: #E4E4E4;
            border-radius: 20px;
            padding: 16px;
            max-height: 30em;
            transition: 0.4s;
        
            &:hover {
                box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            }
    
            svg {
                color: var(--rs-primary-500);
            }
    
            p {
                color: #595959;
                line-height: 19.2px;
                font-size: 100%;
            }
    
            p.salary-range {
                color: #212121;
                line-height: 22.4px;
                font-weight: 700;
                font-size: 110%;
            }
    
            .rs-stack {
                >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                    width: 100% !important;
    
                    .rs-stack,.rs-stack-item {
                        width: 100% !important;
                    }
                }
            }
    
            .rs-col.company-info {
                p {
                    margin-bottom: 5px;
                }
    
                b {
                    font-size: 1.2em;
                    color: black;
                    font-weight: 700;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
    
            .rs-stack {
                width: 100%;
            }
    
            .rs-btn-icon {
                width: 100%;
                padding: 16px 8px;
            }

            .rs-btn-icon:disabled {
                background: #E4E4E4;
                color: #ABABAB;
                opacity: 0.7;
            }
    
            .rs-stack-item .rs-stack-item:first-child {
                .rs-badge {
                    background-color: #E9A0FF;
                }
            }
    
            .rs-stack-item .rs-stack-item:nth-child(2) {
                .rs-badge {
                    background-color: #FFDE59;

                    &.rs-badge-fulltime {
                        background-color: #003884 !important;
                        color: #ffffff !important;
                    }
                }
            }
    
            .rs-stack-item .rs-stack-item:last-child {
                .rs-badge {
                    background-color: #90BFFF;
                }
            }
    
            .rs-badge {
                border-radius: 20px;
                padding: 2px 10px;
                font-weight: 400;
                white-space: nowrap;
                color: #212121;
            }
        }
    }
}