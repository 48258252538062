.myapplication-main-content {
    .rs-col-xs-24 {
        margin-top: 2em;
    }

    .rs-row:first-child {
        .rs-col-xs-24 {
            margin-top: 0px;
        }
    }

    a {
        color: #595959;
        span {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            span {
                color: #212121;
            }

            svg {
                color: #212121;
            }
        }
    }

    .rs-nav {
        padding: 10px;
        width: 100% !important;
        background: #FFFFFF;
        display: flex;
        border-radius: 30px;
        margin-bottom: 32px !important;

        @media screen and (max-width: 425px) {
            width: auto !important;

            a[role="button"] {
                width: 33%;
            }
        }

        .rs-nav-item {
            border-radius: 30px;
            color: black;
            text-align: center;
            font-weight: 600;
            font-size: 1em;
            padding: 10px 20px;
            line-height: 22.4px;
            margin-top: 2.5%;
            margin-bottom: 2.5%;

            small {
                font-size: 0.8em;
                display: block;
                font-weight: 200;
            }
        }

        img[alt="logo-step"] {
            display: block;
            margin-left: 35%;
            margin-bottom: 8px;
        }

        .rs-nav-item-active,.rs-nav-item:hover {
            color: black !important;
        }

        @media screen and (max-width: 425px) {
            .rs-divider {
                display: none;
            }
        }
    }


    .cultures-icon {
        text-align: center;

        &:nth-child(n+1) {
            margin-top: 24px !important;
        }

        @media screen and (min-width: 784px) {
            &:nth-child(1) {
                margin-top: 0px !important;
            } 
            &:nth-child(2) {
                margin-top: 0px !important;
            } 
            &:nth-child(n+3) {
                margin-top: 36px !important;
            }   
        }
        
        @media screen and (min-width: 1024px) {
            &:nth-child(3) {
                margin-top: 0px !important;
            } 
            &:nth-child(1) {
                margin-top: 0px !important;
            } 
            &:nth-child(2) {
                margin-top: 0px !important;
            } 

            &:nth-child(n+4) {
                margin-top: 36px !important;
            }   
        }
    }

    .myapplication-wrapper-card {
        border-radius: 20px 20px 0px 0px;

        img {
            object-fit: cover;
            height: 326px;
            opacity: 0.5;
        }

        img[alt="company-logo"] {
            height: 84px;
            width: 84px;
            object-fit: contain;
            border-radius: 50%;
            position: absolute;
            top: 284px;
            left: 5%;
            opacity: 1;
            background-color: white;
        }

        .rs-placeholder-graph.placeholder-logo {
            height: 84px !important;
            width: 84px !important;
            border-radius: 50%;
            position: absolute;
            top: 284px;
            left: 5%;
            opacity: 1;
            background-color: white;
        }

        h2.company-name {
            color: #595959;
            opacity: 0.8;
            font-weight: 600;
            position: absolute;
            left: 4%;
            top: 310px;
            font-size: 1.3em;
        }

        h2.company-title {
            position: absolute;
            left: 4%;
            top: 340px;
            font-size: 1.5em;
        }

        .myapplication-header-btn {
            position: absolute;
            right: 4%;
            top: 320px;
        }

        @media screen and (max-width: 425px) {
            .myapplication-header-btn {
                display: none;
            }
        }
    }

    .myapplication-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        transition: 0.4s;

        .company-info {
            p {
                font-size: 0.9em;
            }

            b {
                margin-bottom: 5px;
                font-weight: 600;
                color: black;
                font-size: 1.1em;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
        }

        img[alt="company-culture-logo"] {
            display: block;
            width: 64px;
            height: 64px;
            opacity: 1;
            margin: auto;
            margin-bottom: 8px;
        }

        .rs-divider {
            width: 100%;
        }

        img[alt="company-culture-logo"]+b {
            font-size: 1.2em;
        }

        p.culture-text {
            margin-top: 8px !important;
            font-size: 1em !important;
        }

        .loading-skeleton>.rs-stack-item {
            width: 100%;
        }

        svg {
            color: #004AAD;
        }

        p {
            font-size: 1em;
        }

        p.myapplication-title {
            color: #212121;
            font-weight: 700;
            font-size: .95rem;
            line-height: 25.2px;
            margin-bottom: 8px;
        }

        a.myapplication-offerletter {
            color: #004AAD;
            text-decoration: underline;
        }

        h3.myapplication-title {
            font-size: 2.4em;
            margin-right: 8px;
        }

        svg {
            width: 24px;
            height: 24px;
            margin-top: 8px;

            @media screen and (max-width: 425px) {
                margin-top: -8px;
            }
        }

        .rs-stack-item {
            width: 100%;
        }

        button {
            margin-top: 1em;
            float: right;
            font-size: .9em !important;
        }

        @media screen and (max-width: 425px) {
            #myapplication-submit-test-button {
                margin-top: 16px;
            }   
        }
    }

    .rs-panel.company-job-opening-card {
        margin-top: 24px !important;
        border-radius: 20px;

        .rs-panel {
            background-color: white !important;
        }
    }

    .myapplication-side-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        padding-bottom: 0px;
        transition: 0.4s;
        width: 100%;

        .rs-row>.rs-col {
            margin-bottom: 16px;
        }

        .rs-row>.rs-col:last-child {
            margin-bottom: 0px !important;
        }

        .rs-stack-item {
            width: 100%;
        }

        p, a {
            font-size: 1em;
        }

        .rs-col-xs-3 {
            svg {
                color: #004AAD;
                min-height: 48px;
            }
        }

        p.myapplication-title {
            color: #212121;
            font-weight: 700;
            font-size: .95rem;
            line-height: 25.2px;
        }

        p.myapplication-attribute-title {
            color: #212121;
            font-weight: 700;
            line-height: 25.2px;
            font-size: .8rem;
            margin-left: 0.5em;
        }

        p.myapplication-attribute-title+p {
            font-size: .8rem;
            margin-left: 0.5em;
        }

        .rs-timeline-item-content {
            font-size: .75rem;
        }

        @media screen and (max-width: 425px) {
            margin-top: 2em;
        }
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        img.bg-myapplication-initiated {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }

        img.bg-apply-job {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }
    }
}

.upload-solution-modal-body {
    text-align: start !important;

    b {
        font-size: 1.2em;
    }

    p, label {
        font-size: 1em;
    }
 }

.upload-solution-modal-footer {
   text-align: end !important;
}