.app {
    background-image: url('/images/bg-candidate-login.png');
    background-size: cover;
    margin-top: 0;
}

.forgot-pass-container {
    color: #212121;
    font-weight: 600;
    line-height: 140%;
    margin-top: 17.5vh;
    margin-bottom: 3.5vh;

    justify-content: center;

    img[src="bg-forgot-pass.png"] {
        width: 60%;
        margin-left: 20%;
    }

    .forgot-pass-panel {
        width: 100% !important;
        border: #191F50 !important;
    
        .rs-panel-header {
            color: #212121;
            font-weight: 700;
            text-align: center;

            .rs-panel-title>span {
                font-size: 32px;
                font-weight: 700;
                text-align: center;
            }

            .rs-panel-title>small {
                font-size: 16px;
                font-weight: 400;
                opacity: 0.75;
                line-height: 22.4px;
                margin-top: 8px;
                color: #595959;
                display: block;
                text-align: center;
            }
        }
    
        a {
            color: var(--rs-message-info-icon);
        }
    }
}

.back-to-login-section {
    font-weight: 400;
    margin-top: 0px;
    text-align: center;

    a {
        color: #212121 !important;
        font-weight: 400;
        cursor: pointer;
    }
}

@media screen and (max-width: 1024px) {
    .forgot-pass-panel {
        width: 50% !important;

        .rs-panel-title {
            >span {
                font-size: 1.5em !important;
            }

            >small {
                font-size: 0.9em !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .forgot-pass-panel {
        width: 80% !important;
    }
}