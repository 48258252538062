.footer {
    padding-top: 8vh;
    padding-bottom: 3vh;
    padding-left: 3rem;
    padding-right: 3rem;
    background-color: #004AAD !important;

    h5 {
        color: white;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 8px;
    }

    a {
        color: #C2D4EB;
        font-weight: 400;
    }

    p {
        color: #C2D4EB;
        font-weight: 400;
    }

    .logo-text {
        width: 157.07px;
        height: 22px;
    }

    .logo {
        width: 30px;
        height: 30px;
        margin-right: 8px;
    }

    .social-media-logo {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
        background-color: #E4F0FF;
    }

    .social-media-logo:hover {
        background-color: white;
    }

    .description-text {
        line-height: 24px;
        padding: 1.7rem 0px 0.9rem;
    }

    .copyright-text {
        padding-top: 12vh;
    }

    .privacy-policy-text {
        padding-top: 12vh !important;
        padding-right: 3rem;
        float: right;
        display: block;

        >a:hover {
            color: #C2D4EB;
        }
    }

    .privacy-policy-mobile-text {
        display: none;

        >a:hover {
            color: #C2D4EB;
        }
    }
}

@media screen and (width: 768px) {
    .footer {
        .rs-flex-box-grid-item:nth-child(2) {
            .rs-col:nth-child(2) {
                margin-top: 16px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .footer {
        .copyright-text {
            padding-top: 4vh;
        }
    
        .privacy-policy-text {
            display: none !important;
        }
    
        .privacy-policy-mobile-text {
            display: block !important;
        }

        .rs-flex-box-grid-item:nth-child(2) {
            margin-top: 48px;
        }
    }
}

@media screen and (max-width: 320px) {
    .footer {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}