.rs-modal-dialog {
    margin-top: 7.5vh;
}

.job-details-main-content {
    .rs-col-xs-24 {
        margin-top: 2em;
    }

    .rs-row:first-child {
        .rs-col-xs-24 {
            margin-top: 0px;
        }
    }

    a {
        color: #595959;
        span {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            span {
                color: #212121;
            }

            svg {
                color: #212121;
            }
        }
    }

    .learn-more-btn {
        color: #90BFFF;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        padding-left: 0px !important;
        text-align: left;

        svg {
            margin-left: 4px;
            padding-top: 4px !important;
        }
    }

    .job-details-wrapper-card {
        border-radius: 20px 20px 0px 0px;

        div.card-header-wrapper {
            mask-size: 400px 327px;
            mask-image: -webkit-gradient(linear, center bottom, center top, 
            color-stop(0.00,  rgba(0,0,0,0)),
            color-stop(1.00,  rgba(0,0,0,1)));
            
            img {
                object-fit: cover;
                height: 327px;
            }
        }

        h2.job-company-name {
            color: #595959;
            opacity: 0.8;
            font-weight: 600;
            position: absolute;
            left: 4%;
            top: 290px;
            font-size: 1.3em;

            @media screen and (max-width: 425px) {
                left: 7%;
            }
        }

        h2.job-title {
            position: absolute;
            left: 4%;
            top: 320px;
            font-size: 1.5em;

            @media screen and (max-width: 425px) {
                left: 7%;
            }
        }

        .job-details-header-btn {
            position: absolute;
            right: 4%;
            top: 310px;
        }

        @media screen and (max-width: 425px) {
            .job-details-header-btn {
                display: none;
            }
        }

        .job-details-card {
            background-color: #FFFFFF;
            color: #595959;
            border: 1px solid #E4E4E4;
            border-radius: 0px 0px 20px 20px;
            padding: 20px 10px;
            transition: 0.4s;

            .loading-skeleton>.rs-stack-item {
                width: 100%;
            }
    
            svg {
                color: #004AAD;
            }
    
            p {
                font-size: 1em;
            }
            
            p.job-requirements span, p.job-description span, p.job-requirements li, p.job-description li {
                font-size: 1em !important;
            }
    
            p.job-details-title {
                color: #212121;
                font-weight: 700;
                font-size: 1.2em;
                margin-left: 8px;
                line-height: 25.2px;
            }
        }
    }

    .job-details-company-card {
        background-color: #FFFFFF;
        color: #595959;
        border: 1px solid #E4E4E4;
        border-radius: 20px;
        padding: 20px 10px;
        transition: 0.4s;

        .rs-row>.rs-col {
            margin-bottom: 16px;
        }

        .rs-row>.rs-col:last-child {
            margin-bottom: 0px !important;
        }

        .rs-stack-item {
            width: 100%;
        }

        span, p {
            font-size: 1em;
        }

        .rs-col-xs-3 {
            svg {
                color: #004AAD;
                min-height: 48px;
            }
        }

        p.job-details-company-title {
            color: #212121;
            font-weight: 700;
            font-size: 1.2em;
            line-height: 25.2px;
        }

        p.job-details-attribute-title {
            color: #212121;
            font-weight: 700;
            font-size: 1em;
            line-height: 25.2px;
        }
    }
}

.job-list {
    >.rs-stack-item {
        width: 100%;

        .rs-placeholder-graph {
            width: 80% !important;
            margin-left: 10%;
            border-radius: 20px;
        }

        .job-card {
            cursor: pointer;
            background-color: #FFFFFF;
            color: #E4E4E4;
            border-radius: 20px;
            padding: 16px;
            width: 88%;
            margin-left: 6%;
            max-height: 390px;
            transition: 0.4s;
        
            &:hover {
                box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            }
        
            svg {
                color: var(--rs-primary-500);
            }

            img[alt="logo-startup"] {
                object-fit: contain;
                background-color: white;
            }
        
            p {
                color: #595959;
                line-height: 19.2px;
                font-size: 100%;
            }
        
            p.salary-range {
                color: #212121;
                line-height: 22.4px;
                font-weight: 700;
                font-size: 110%;
            }
        
            .rs-stack {
                >.rs-stack-item:last-child,>.rs-stack-item:nth-child(1),>.rs-stack-item:nth-child(3) {
                    width: 100% !important;
        
                    .rs-stack,.rs-stack-item {
                        width: 100% !important;
                    }
                }
            }
        
            .rs-col.company-info {
                p {
                    margin-bottom: 5px;
                    overflow: hidden;
                    font-size: .95rem;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
        
                b {
                    font-size: .95rem;
                    color: black;
                    font-weight: 700;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        
            .rs-stack {
                width: 100%;
            }
        
            .rs-btn-icon {
                width: 100%;
                padding: 16px 8px;
            }
        
            .rs-stack-item .rs-stack-item:first-child {
                .rs-badge {
                    background-color: #E9A0FF;
                    font-size: .7rem;
                }
            }
        
            .rs-stack-item .rs-stack-item:nth-child(2) {
                .rs-badge {
                    background-color: #FFDE59;

                    &.rs-badge-fulltime {
                        font-size: .7rem;
                        background-color: #003884 !important;
                        color: #ffffff !important;
                    }
                }
            }
        
            .rs-stack-item .rs-stack-item:last-child {
                .rs-badge {
                    background-color: #90BFFF;
                    font-size: .7rem;
                }
            }
        
            .rs-badge {
                border-radius: 20px;
                padding: 2px 10px;
                font-weight: 400;
                white-space: nowrap;
                color: #212121;
            }
        }
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        img.bg-apply-job {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }
    }
}

#bottom-action {
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background-color: white;
    box-shadow: 6px 0px 12px rgba(0, 0, 0, 0.1);
    padding: 20px 10px;
    display: none;

    .rs-btn-icon {
        width: 100%;
        height: 52px;
        color: #004AAD;
    }

    @media screen and (max-width: 425px) {
        display: block;
    }
}