.search-company-main-content {
    h1 {
        text-align: left !important;
        line-height: 35.2px;
        font-size: 32px;
        text-transform: capitalize;
    }

    >.rs-col {
        margin-bottom: 40px !important;
    }

    .rs-tag {
        background-color: #FFDE59;
        border-radius: 30px;
        color: #212121;

        .rs-tag-text {
            font-weight: 200;
            margin: 8px 5px;
            font-size: 12px;
        }
    }

    .rs-placeholder-graph {
        border-radius: 20px;
        margin-bottom: 24px;
        min-height: 390px;
    }

    .rs-placeholder-graph.loader-category {
        height: 120px !important;
        min-height: 20vh;
        max-height: 120px;
    }

    .search-results-row {
        margin-bottom: 24px;

        >.rs-col {
            padding-left: 12px;
            padding-right: 12px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            >.rs-col {
                margin-bottom: 24px;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
        }
    }

    .rs-input-group-addon,.rs-input-group-addon:hover {
        margin-top: 9px;
        color: #3F77C2 !important;
        background-color: transparent !important;
        height: max-content !important;
    }

    .rs-input,.rs-picker-toggle,.rs-picker-tag {
        padding: 17.5px 20px !important;
        padding-left: 36px !important;
        min-height: 58px;
    }

    .rs-picker-tag {
        min-height: 58px;
        color: #212121;
        padding: 4px 8px !important;

        .rs-picker-toggle {
            min-height: max-content;
            padding: 12px !important;
            padding-left: 17.5px !important;
        }
    }

    .rs-picker-select,.rs-picker-tag {
        width: 100%;
    }

    .search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        cursor: pointer;
        border-radius: 20px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 0px !important;
        max-height: 22em;
        min-height: 22em;
        transition: 0.4s;

        img[alt="card-header"] {
            height: 165px;
            object-fit: cover;
        }

        img[alt="company-logo"] {
            height: 48px;
            width: 48px;
            object-fit: contain;
            border-radius: 50%;
            position: absolute;
            top: 35%;
            left: 7.5%;
            background-color: white;
        }

        &:hover {
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
        }

        svg {
            color: var(--rs-primary-500);
        }

        p {
            color: #595959;
            line-height: 19.2px;
            font-size: 100%;
        }

        p.salary-range {
            color: #212121;
            line-height: 22.4px;
            font-weight: 700;
            font-size: 110%;
        }

        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;

                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }
        }

        .rs-col.company-info {
            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }
        }

        .rs-col.company-info:last-child {
            p {
                margin-top: 8px;
                font-weight: 400;
                line-height: 140%;
                /* Black / 90 */
                color: #595959;

            }
        }

        .rs-stack {
            width: 100%;
        }

        .rs-btn-icon {
            width: 100%;
            padding: 16px 8px;
        }

        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }

        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;

                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }

        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }

        .rs-badge {
            border-radius: 4px;
            padding: 2px 10px;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }

        .jobs-count {
            >b {
                line-height: 140%;
                font-weight: 600;
                color: #212121;
            } 
        }

        @media screen and (max-width: 425px) {
            .opening-jobs-container {
                margin-top: 8px;
            }   
        }
    }
}
