.dashboard-panel-blue {
    @media screen and (max-width: 425px) {
        padding: 8%;
        padding-left: 5%;
        padding-right: 5%;
    }

    h1 {
        text-align: left;
        font-size: 2.25em !important;
        margin-bottom: 8px;
        text-transform: capitalize;

        @media screen and (max-width: 425px) {
            text-align: center;
            width: 100%;
            white-space: pre-wrap;
        }

        @media screen and (min-width: 1366px) {
            padding-top: 5%;
        }
    }

    p {
        text-align: justify;
        color: #595959;
        line-height: 140%;
        margin-top: 16px;
        padding-right: 16px;
    }

    img {
        margin-top: -17.5%;
        width: 90%;
    }

    @media screen and (max-width: 425px) {
        img {
            width: 50%;
            margin-left: 25%;
            margin-top: -7.5%;
            margin-bottom: 24px;
        }

        p {
            padding-right: 0px;
        }
    }
}

.profile-container {
    h2 {
        margin-top: 24px;
        margin-bottom: 24px;
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.25em;
    }

    .rs-uploader-picture .rs-uploader-trigger-btn {
        border: none;
        background-color: transparent !important;
    }

    .profile-location {
        margin-top: 12px;

        svg {
            color:var(--rs-primary-500);
        }

        p {
            margin-left: 8px;
            display: inline-block;
            color: #595959;
            font-size: 1em;
        }
    }

    .rs-nav {
        padding: 10px;
        width: fit-content !important;
        background: #FFFFFF;
        border-radius: 30px;
        margin-bottom: 32px !important;

        @media screen and (max-width: 425px) {
            width: auto !important;

            a[role="button"] {
                width: 33%;
            }
        }

        .rs-nav-item {
            border-radius: 30px;
            color: black;
            font-weight: 600;
            padding: 10px 20px;
            line-height: 22.4px;
        }

        .rs-nav-item:focus {
            background-color: var(--rs-primary-500);
        }

        .rs-nav-item-active,.rs-nav-item:hover {
            color: white !important;
            background-color: var(--rs-primary-500);
        }
    }

    .rs-panel {
        background-color: white;
        border-radius: 20px;
        padding: 24px 12px;

        .rs-tag {
            background-color: #FFDE59;
            border-radius: 30px;
            color: #212121;

            .rs-tag-text {
                font-weight: 200;
                margin: 8px 5px;
                font-size: 12px;
            }
        }

        .rs-input[type="text"]:disabled {
            background-color: #F7FBFF;
        }

        .rs-badge {
            background: #D5E7FF;
            color: #70ACFF;
            padding: 6px 10px;
            font-weight: 600;
        }

        div[role="combobox"] {
            max-inline-size: auto;
        }

        .rs-form-control-label {
            color: #595959;
        }

        .rs-avatar {
            min-height: 75px;
            min-width: 75px;
            background-color: white;

            @media screen and (min-width: 768px) {
                margin-top: 15%;
            }
        }

        .rs-uploader {
            .rs-uploader-trigger-btn {
                background-color: #F7FBFF;
            }

            span {
                color: #595959;
            }
        }

        .rs-row {
            .rs-col-xs-8 {
                padding-top: 8px !important;
            }

            .rs-col-xs-16 {
                .rs-col-xs-8 {
                    padding-top: 3px !important;
                }
            }

            margin-bottom: 1.5em;

            label {
                color: #595959;
                font-weight: 400;
            }

            .rs-picker {
                color: black;

                width: 100%;
                .rs-picker-search {
                    width: 100% !important;
                    .rs-picker-search-input>input {
                        max-width: 250px !important;
                    }
                }
            }
        }

        .rs-row:last-child {
            margin-bottom: 0px;
        }
    }
}

.rs-content {
    background-color: #F6F6F6;
}

.basicinfo-general-test {
    p {
        color: #212121;
        text-align: justify;
        font-size: 1em;
    }
}

.rs-avatar-image[alt="user-profile-avatar"] {
    border-radius: 50% !important;
    object-fit: contain;
    height: 5em !important;
    width: 5em !important;
}

.rs-avatar-image[alt="general-test"] {
    border-radius: 50% !important;
    object-fit: contain;
    height: 2.5em;
    width: 2.5em;
}

.basicinfo-general-test-score-panel {
    background: #F7FBFF !important;
    border-radius: 20px;
    padding: 8px;

    .rs-avatar {
        margin-top: 0px !important;
    }

    .score-text,.date-taken-text {
        font-size: 0.9em;
        color: #212121;
        margin-top: 0px !important;
        font-weight: 400;
    }

    @media screen and (max-width: 425px) {
        .score-text {
            margin-top: 6px;
        }
    }
}

.curatedjobs-panel {
    background-color: white;
    border-radius: 20px !important;
    padding: 16px 8px;
    margin-top: 24px;

    .rs-placeholder-graph {
        height: 120px !important;
        border-radius: 20px;
        margin-top: 1rem;
    }

    h3 {
        text-align: left;
        font-size: 1.25em;
    }

    .curatedjobs-card {
        background-color: #F7FBFF;
        border-radius: 12px;
        padding: 8px;
        padding-top: 4px;

        cursor: pointer;
        transition: 0.4s;

        &:hover {
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
        }

        .rs-avatar {
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            background: white;
            width: 1.8em;
            height: 1.8em;

            .rs-avatar-image{
                object-fit: contain;
                border-radius: 50% !important;
            }
        }

        h3 {
            text-align: left;
            font-size: .9em;
            line-height: 125%;
            margin-bottom: 2px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p {
            font-size: .8em;
            margin-top: 4px;
            line-height: 100%;
            color: #595959;
        }
    }

    .curatedjobs-card:first-child {
        margin-top: 0px !important;
    }

    .curatedjobs-card:not(:first-child) {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 425px) {
    .badge-seeking-status {
        margin-top: 12px;
    }
}

.tg-img-uploader {
    // background: blue;
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .rs-avatar {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }
    
}

.click-to-edit {
    text-align: center;
    color: black;
    font-size: 12px;
}

.tg-tooltip {
    min-width: 320px;
    text-align: left;
}