.app-hp {
    background-image: url('/images/bg-hiring-partner-login.png');
    background-size: cover;
    margin-top: 0;
}

.rs-steps {
    margin-top: 17vh;
    width: 50%;
    margin-left: 25% !important;
}

.rs-modal-content {
    margin-top: 15vh;

    .rs-modal-body {
        text-align: center;

        img.bg-hp-signup {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }

        h3 {
            margin-top: 1em;
        }

        p {
            margin-top: 1em;
            text-align: center;
        }
    }
}

.signup-container#hiring-partner {
    color: white;
    font-weight: 600;
    line-height: 140%;
    padding-top: 12.5vh;
    margin-bottom: 3.5vh;

    min-height: 80vh;
    justify-content: center;

    .hp-signup-instruction {
        color: #CACACA;
        font-size: 0.9em;
        font-weight: 200;
        margin-bottom: 8px;

        a {
            color: white !important;
            font-size: 1em !important;
            font-weight: 600 !important;
            text-decoration: underline;
        }
    }

    .rs-panel-body .rs-form-group:first-child {
        margin-top: 1rem;
    }

    .rs-checkbox-wrapper .rs-checkbox-inner:before {
        border: 1px solid white !important;
    }

    .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before {
        border: 1px solid transparent !important;
    }

    .rs-uploader-file-item {
        .rs-uploader-file-item-title {
            color: white;
        }
    
        .rs-uploader-file-item-icon-wrapper {
            color: white;
        }
    }

    .rs-uploader-file-item:hover {
        .rs-uploader-file-item-title {
            color: #212121;
        }
    
        .rs-uploader-file-item-icon-wrapper {
            color: #212121;
        }
    }

    .signup-panel {
        width: 40%;
        border: #191F50 !important;
    
        .rs-panel-header {
            color: #212121;
            font-weight: 700;

            .rs-panel-title>p {
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 16px;
                line-height: 120% !important;
                text-align: center;
                color: white;
                white-space: pre-line;
            }

            .rs-panel-title>small {
                font-size: 16px;
                font-weight: 400;
                opacity: 0.75;
                line-height: 22.4px;
                margin-top: 8px;
                color: white;
                display: block;
                text-align: center;
                white-space: nowrap;
            }
        }
    
        a {
            color: var(--rs-message-info-icon);
        }
    }

    .signup-panel-steps {
        width: 50%;
        margin-left: 25%;

        .rs-panel-header {
            color: white;
            font-weight: 700;

            .rs-panel-title>p {
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 16px;
                line-height: 120% !important;
                text-align: center;
                color: white;
                white-space: pre-line;
            }

            .rs-panel-title>small {
                font-size: 16px;
                font-weight: 400;
                opacity: 0.75;
                line-height: 22.4px;
                margin-top: 8px;
                color: white;
                display: block;
                text-align: left;
                white-space: nowrap;
            }
        }
    }

    .rs-uploader-trigger-btn {
        background-color: #F8FBFF;

        span {
            color: #212121;
            font-weight: 400;
            display: block;
            text-align: center;

            >a {
                font-weight: 700;
                color: #004AAD;
                text-decoration-line: underline;
            }

            >small {
                color: #595959;
            }
        }
    }

    .phone-prefix {
        width: 100%;
    }

    .create-account-section {
        font-weight: 400;
        margin-top: 0px;
    
        a {
            color: white !important;
            font-weight: 700;
            cursor: pointer;
        }
    }
    
    .agreement-section {
        margin-bottom: 6px;
        font-weight: 400;

        &:last-child {
            margin-bottom: 2rem;
        }

        span {
            display: block;
            font-size: 1em;
    
            a {
                font-size: 1em;
                font-weight: 700;
                color: white !important;
                cursor: pointer;
            }
        }
    }
}

.signup-container-steps#hiring-partner {
    margin-top: 5vh;
    padding-top: 0vh;

    .rs-uploader-file-item {
        .rs-uploader-file-item-title {
            color: white;
        }
    
        .rs-uploader-file-item-icon-wrapper {
            color: white;
        }
    }

    .rs-uploader-file-item:hover {
        .rs-uploader-file-item-title {
            color: #212121;
        }
    
        .rs-uploader-file-item-icon-wrapper {
            color: #212121;
        }
    }
}

.rs-steps#hp {
    .rs-steps-item-status-wait {
        .rs-steps-item-icon-wrapper {
            background: #759DD3;
            color: white;
            border-color: #759DD3;
            font-weight: 600;
        }
    
        .rs-steps-item-title {
            color: #4077C1 !important;
        }
    }
    
    .rs-steps-item-status-process {
        .rs-steps-item-icon-wrapper {
            font-weight: 600;
        }
    
        .rs-steps-item-title {
            color: white;
            font-weight: 600;
        }
    }
}

a.back-btn#hp {
    color: white !important;
    span {
        margin-left: 8px !important;
    }

    &:hover {
        text-decoration: none;
        span {
            color: white;
        }

        svg {
            color: white;
        }
    }
}

@media screen and (max-width: 1024px) {
    .signup-panel {
        width: 50% !important;

        .rs-panel-title {
            >span {
                font-size: 1.5em !important;
            }

            >small {
                font-size: 0.9em !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .signup-panel,.signup-panel-steps  {
        width: 65% !important;
    }

    .signup-panel-steps {
        margin-left: 17.5% !important;
    }

    .rs-steps {
        margin-top: 20vh;
        width: 70%;
        margin-left: 15% !important;
    }
}


@media screen and (max-width: 425px) {
    .signup-panel,.signup-panel-steps {
        width: 90% !important;
    }

    .signup-panel-steps {
        margin-left: 5% !important;
    }

    .agreement-section {
        span {
            white-space: normal;
        }
    }

    .rs-steps {
        width: 80%;
        margin-left: 10% !important;
    }
}