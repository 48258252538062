.app {
    background-image: url('/images/bg-candidate-login.png');
    background-size: cover;
    margin-top: 0;
}

.rs-content {
    background-color: transparent;
}

.rs-steps {
    margin-top: 17vh;
    width: 50%;
    margin-left: 25% !important;
}

a.back-btn {
    color: #595959;
    span {
        margin-left: 8px;
    }

    &:hover {
        text-decoration: none;
        span {
            color: #212121;
        }

        svg {
            color: #212121;
        }
    }
}

.rs-steps-item-status-wait {
    .rs-steps-item-icon-wrapper {
        background: #759DD3;
        color: white;
        border-color: #759DD3;
        font-weight: 600;
    }

    .rs-steps-item-title {
        color: #759DD3;
    }
}

.rs-steps-item-status-process {
    .rs-steps-item-icon-wrapper {
        font-weight: 600;
    }

    .rs-steps-item-title {
        color: #004AAD;
        font-weight: 600;
    }
}

.basic-info-container {
    color: #212121;
    font-weight: 600;
    line-height: 140%;
    margin-top: 7.5vh;
    margin-bottom: 3.5vh;

    min-height: 80vh;
    justify-content: center;

    .basic-info-panel {
        width: 40%;
        margin-left: 30%;
        border: #191F50 !important;
    
        .rs-panel-header {
            color: #212121;
            font-weight: 700;

            .rs-panel-title>span {
                font-size: 32px;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
            }

            .rs-panel-title>small {
                font-size: 16px;
                font-weight: 400;
                opacity: 0.75;
                line-height: 22.4px;
                margin-top: 8px;
                color: #595959;
                display: block;
            }
        }

        .rs-tag {
            background-color: #FFDE59;
            border-radius: 30px;
            color: #212121;

            .rs-tag-text {
                font-weight: 200;
                margin: 8px 5px;
                font-size: 12px;
            }
        }

        .rs-slider-bar {
            background-color: #90BFFF;
        }

        .rs-slider-handle::before {
            background-color: var(--rs-slider-progress);
        }

        .rs-picker {
            width: 100%;

            div[role="combobox"].rs-btn-sm {
                height: 36px;
            }

            .rs-picker-search {
                width: 100% !important;
                .rs-picker-search-input>input {
                    max-width: 250px !important;
                }
            }
        }

        .rs-uploader-trigger-btn {
            background-color: #F8FBFF;

            span {
                color: #212121;
                font-weight: 400;
                display: block;
                text-align: center;

                >a {
                    font-weight: 700;
                    color: #004AAD;
                    text-decoration-line: underline;
                }

                >small {
                    color: #595959;
                }
            }
        }

        .rs-slider {
            width: 90%;
            margin-left: 5%;
        }
    
        a {
            color: var(--rs-message-info-icon);
        }
    }
}

@media screen and (max-width: 1024px) {
    .basic-info-panel {
        width: 50% !important;
        margin-left: 25% !important;

        .rs-panel-title {
            >span {
                font-size: 1.5em !important;
            }

            >small {
                font-size: 0.9em !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .basic-info-panel {
        width: 65% !important;
        margin-left: 17.5% !important;
    }

    .rs-steps {
        margin-top: 20vh;
        width: 70%;
        margin-left: 15% !important;
    }
}


@media screen and (max-width: 425px) {
    .basic-info-container {
        .basic-info-panel {
            width: 90% !important;
            margin-left: 5% !important;
        }
    }

    .rs-steps {
        width: 80%;
        margin-left: 10% !important;
    }
}