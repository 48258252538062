.job-category-container {
    background-color: #f6f6f6 !important;

    .search-results-container {
        padding: 0px 3.5rem;

        @media screen and (max-width: 768px) {
            padding-left: 16px !important;
            padding-right: 16px !important;
        }

        h1 {
            font-size: 32px;
            margin-left: 8px;
            line-height: 35.2px;
            margin-bottom: 36px;
            text-transform: capitalize;
            text-align: left !important;
        }

        .rs-input-group-addon,.rs-input-group-addon:hover {
            margin-top: 9px;
            color: #3F77C2 !important;
            background-color: transparent !important;
            height: max-content !important;
        }
        
        .rs-picker-select,.rs-picker-tag {
            width: 100%;
        }

        // .rs-picker-toggle,.rs-picker-tag-wrapper {
        //     height: 52px !important;
        // }
    
        @media screen and (max-width: 768px) {
            h1 {
                margin-left: 8px;
                margin-bottom: 24px;
            }
        }

        .search-results-row {
            margin-bottom: 24px;
    
            >.rs-col {
                padding-left: 12px;
                padding-right: 12px;
            }
    
            @media screen and (max-width: 768px) {
                margin-bottom: 0px;
                >.rs-col {
                    margin-bottom: 24px;
                }
            }
    
            .rs-avatar-image {
                object-fit: contain;
                background-color: white;
                object-fit: contain;
            }
        }

        .rs-placeholder-graph {
            border-radius: 20px;
            margin-bottom: 24px;
            min-height: 27.5em;
        }

        .search-result-card {
            background-color: #FFFFFF;
            color: #E4E4E4;
            border-radius: 20px;
            padding: 16px;
            max-height: 30em;
            transition: 0.4s;
        
            &:hover {
                box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
            }
    
            svg {
                color: var(--rs-primary-500);
            }
    
            p {
                color: #595959;
                line-height: 19.2px;
                font-size: 100%;
            }
    
            p.salary-range {
                color: #212121;
                line-height: 22.4px;
                font-weight: 700;
                font-size: 110%;
            }
    
            .rs-stack {
                >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                    width: 100% !important;
    
                    .rs-stack,.rs-stack-item {
                        width: 100% !important;
                    }
                }

                .rs-stack-item .industry-name {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
    
            .rs-col.company-info {
                p {
                    margin-bottom: 5px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
    
                b {
                    font-size: 1.2em;
                    color: black;
                    font-weight: 700;
                    overflow: hidden;
                    min-height: 47.98px;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
    
            .rs-stack {
                width: 100%;
            }
    
            .rs-btn-icon {
                width: 100%;
                padding: 16px 8px;
            }
    
            .rs-stack-item .rs-stack-item:first-child {
                .rs-badge {
                    background-color: #E9A0FF;
                }
            }
    
            .rs-stack-item .rs-stack-item:nth-child(2) {
                .rs-badge {
                    background-color: #FFDE59;

                    &.rs-badge-fulltime {
                        background-color: #003884 !important;
                        color: #ffffff !important;
                    }
                }
            }
    
            .rs-stack-item .rs-stack-item:last-child {
                .rs-badge {
                    background-color: #90BFFF;
                }
            }
    
            .rs-badge {
                border-radius: 20px;
                padding: 2px 10px;
                font-weight: 400;
                white-space: nowrap;
                color: #212121;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .rs-sidebar {
            display: none;
        }   

        padding-top: 2.5rem !important;
    }
}

.job-category-sidebar {
    color: #595959;

    b {
        color: #212121 !important;
    }

    b.filter-title {
        font-weight: 700;
        font-size: 1.3em;
    }

    .clear-all {
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 3px;
        padding-bottom: 3px;
        float: right;
        color: #595959;
        text-decoration: none;
    }

    .clear-all:hover {
        color: #212121;
        text-decoration: none;
    }

    .rs-panel {
        border: 1px solid #E4E4E4;
        border-radius: 12px;
        background-color: white;

        .clear-all {
            padding: 0px !important;
            float: right;
            color: #595959;
            font-size: 1em !important;
            text-decoration: none;
        }

        .rs-slider {
            margin-top: 1.75em;
        }

        .rs-checkbox-group {
            margin-top: 1em;
            .rs-checkbox-checker {
                label {
                    font-size: 0.9em;
                }
            }
        }
    }
}