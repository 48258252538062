.app {
    background-image: url('/images/bg-candidate-login.png');
    background-size: cover;
    margin-top: 0;
}

.signup-container {
    color: #212121;
    font-weight: 600;
    line-height: 140%;
    margin-top: 12.5vh;
    margin-bottom: 3.5vh;

    min-height: 80vh;
    justify-content: center;

    .signup-panel {
        width: 40%;
        border: #191F50 !important;
    
        .rs-panel-header {
            color: #212121;
            font-weight: 700;

            .rs-panel-title>p {
                font-size: 32px;
                font-weight: 700;
                margin-bottom: 16px;
                line-height: 120% !important;
                text-align: center;
                white-space: pre-line;
            }

            .rs-panel-title>small {
                font-size: 16px;
                font-weight: 400;
                opacity: 0.75;
                line-height: 22.4px;
                margin-top: 8px;
                color: #595959;
                display: block;
                text-align: center;
                white-space: nowrap;
            }
        }
    
        a {
            color: var(--rs-message-info-icon);
        }
    }
}

.create-account-section {
    font-weight: 400;
    margin-top: 0px;

    a {
        color: #004AAD !important;
        font-weight: 700;
        cursor: pointer;
    }
}

.agreement-section {
    margin-bottom: 2rem;
    font-weight: 400;

    span {
        display: block;
        white-space: nowrap;
        font-size: 1em;

        a {
            font-size: 1em;
            font-weight: 700;
            color: #212121 !important;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1024px) {
    .signup-panel {
        width: 50% !important;

        .rs-panel-title {
            >span {
                font-size: 1.5em !important;
            }

            >small {
                font-size: 0.9em !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .signup-panel {
        width: 65% !important;
    }
}


@media screen and (max-width: 425px) {
    .signup-panel {
        width: 90% !important;
    }

    .agreement-section {
        span {
            white-space: normal;
        }
    }
}