.app-hp {
    background-image: url('/images/bg-hiring-partner-login.png');
    background-size: cover;
    margin-top: 0;
}

.login-container#hiring-partner {
    font-weight: 600;
    line-height: 140%;
    margin-top: 12.5vh;
    margin-bottom: 3.5vh;

    min-height: 80vh;
    justify-content: center;

    .login-panel {
        width: 40%;

        .rs-panel-body {
            color: white;
        }
    
        border: #191F50 !important;
    
        .rs-panel-header {
            color: white;

            .rs-panel-title>h1 {
                font-size: 32px;
                font-weight: 700;
                text-align: center;
                color: white;
                white-space: pre-line;
            }

            .rs-panel-title>small {
                font-size: 16px;
                font-weight: 400;
                opacity: 0.75;
                line-height: 22.4px;
                margin-top: 8px;
                color: #F6F6F6;
                display: block;
                text-align: center;
                white-space: nowrap;
            }
        }

        .rs-form-group:nth-child(2) {
            margin-top: 1.5rem;
        }
    
        .rs-form-group:first-child {
            margin-top: 2.5rem;
        }
    
        .rs-input:focus {
            border: solid 2px #3782ff
        }
    
        .rs-input:disabled {
            background-color: #030C2F;
        }
    
        a {
            color: var(--rs-message-info-icon);
        }
    }

    .remember-me-section {
        margin-bottom: 2rem;
        font-weight: 400;
    
        .rs-col-xs-12:first-child {
            padding-left: 0px !important;
            margin-left: -4px !important;
        }
    
        span {
            font-size: 1em;
        }
    
        a {
            color: white !important;
            cursor: pointer;
        }

        .rs-checkbox-wrapper .rs-checkbox-inner:before {
            border: 1px solid white !important;
        }
    }

    .create-account-section {
        font-weight: 400;
        margin-top: 0px;
    
        a {
            color: white !important;
            font-weight: 700;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1024px) {
    .login-panel {
        width: 50% !important;

        .rs-panel-title {
            >span {
                font-size: 1.5em !important;
            }

            >small {
                font-size: 0.9em !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .login-panel {
        width: 65% !important;
    }
}


@media screen and (max-width: 425px) {
    .login-panel {
        width: 90% !important;
    }
}