.bg-title {
    padding: 6vh 0px;
    padding-bottom: 5vh;
    background-image: url('/images/bg-privacy-policy.png');
    background-size: cover;

    h1 {
        color: white;
        padding-top: 2vh;
        font-size: 72px;
        line-height: 96px;
        text-align: center;
    }
}

.paragraph-section {
    margin-top: 3vh;
    margin-bottom: 7.5vh;
    padding-left: 15%;
    padding-right: 15%;

    h3 {
        margin-bottom: 1.5rem;
    }

    h5 {
        font-size: 20px;
        color: #212121;
        margin-bottom: 1rem;
    }

    p {
        line-height: 24px;
        text-align: justify !important;

        &.transition-provision-text {
            margin-top: 2rem;
            padding: 30px 34px;
            border: 2px solid #CACACA;
            border-radius: 30px;
        }
    }

    .rs-btn-group {
        margin-bottom: 7.5vh;

        .rs-btn:first-child {
            margin-right: 1rem;
        }

        .rs-btn {
            border-radius: 20px !important;
            background: #FFFFFF;
            border: 1px solid #EFEFEF;
            border-radius: 20px;
            padding: 8px 16px;
            font-weight: 600;
        }

        .rs-btn.active,.rs-btn:hover {
            background: #E4F0FF;
            color: #004AAD;
            font-weight: 600;
            z-index: 1;
            border-radius: 20px !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
}

@media screen and (max-width: 425px) {
    .paragraph-section {
        padding-left: 7.5%;
        padding-right: 7.5%;
    }
}