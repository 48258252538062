.notfound-h1 {
    color: black;
    margin: 16px 0px;
}

.notfound-p {
    color: #3c3c3c;
    margin: 8px 0px;
}

.notfound-btn-back {
    border-radius: 30px;
    cursor: pointer;
    transition: 0.2s ease;
    margin: 24px 0px;
}