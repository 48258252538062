.rs-content {
    background-color: #F6F6F6;
}

.rs-drawer-xs {
    width: 296px !important;
    color: #212121;

    .rs-drawer-body-close {
        display: none;
    }

    .rs-drawer-body {
        padding: 30px !important;

        b.filter-title {
            font-weight: 700;
            font-size: 1.3em;
        }

        .clear-all {
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding-top: 3px;
            padding-bottom: 3px;
            float: right;
            color: #595959;
            text-decoration: none;
        }

        .clear-all:hover {
            color: #212121;
            text-decoration: none;
        }
    }

    .rs-panel {
        border: 1px solid #E4E4E4;
        border-radius: 12px;

        .clear-all {
            padding: 0px !important;
            float: right;
            color: #595959;
            font-size: 1em !important;
            text-decoration: none;
        }

        .rs-slider {
            margin-top: 1.75em;
        }

        .rs-checkbox-group {
            margin-top: 1em;
            .rs-checkbox-checker {
                label {
                    font-size: 0.9em;
                }
            }
        }
    }

    @media screen and (max-width: 425px) {
        width: 100% !important;

        .rs-drawer-body-close {
            display: block !important;
        }

        .rs-drawer-body {
            padding-top: 72px !important;
        }
    }
}

.search-job-main-content {
    >.rs-col {
        margin-bottom: 40px !important;
    }

    .filter-btn {
        background: #E4F0FF;
        width: 58px;
        height: 58px;
        color: #004AAD;
    }

    .rs-tag {
        background-color: #FFDE59;
        border-radius: 30px;
        color: #212121;

        .rs-tag-text {
            font-weight: 200;
            margin: 8px 5px;
            font-size: 12px;
        }
    }

    .rs-placeholder-graph {
        border-radius: 20px;
        margin-bottom: 24px;
        min-height: 390px;
    }

    .rs-placeholder-graph.loader-category {
        height: 120px !important;
        min-height: 20vh;
        max-height: 120px;
    }

    .search-results-row {
        margin-bottom: 24px;

        >.rs-col {
            padding-left: 12px;
            padding-right: 12px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            >.rs-col {
                margin-bottom: 24px;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
        }
    }

    .rs-input-group-addon,.rs-input-group-addon:hover {
        margin-top: 9px;
        color: #3F77C2 !important;
        background-color: transparent !important;
        height: max-content !important;
    }

    .rs-input,.rs-picker-toggle,.rs-picker-tag {
        padding: 17.5px 20px !important;
        padding-left: 36px !important;
        min-height: 58px;
    }

    .rs-picker-tag {
        min-height: 58px;
        color: #212121;
        padding: 4px 8px !important;

        .rs-picker-toggle {
            min-height: max-content;
            padding: 12px !important;
            padding-left: 17.5px !important;
        }
    }

    .rs-picker-select,.rs-picker-tag {
        width: 100%;
    }

    .search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        border-radius: 20px;
        padding: 16px;
        max-height: 30em;
        transition: 0.4s;
    
        &:hover {
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
        }

        svg {
            color: var(--rs-primary-500);
        }

        p {
            color: #595959;
            line-height: 19.2px;
            font-size: 100%;
        }

        p.salary-range {
            color: #212121;
            line-height: 22.4px;
            font-weight: 700;
            font-size: 110%;
        }

        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;

                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }
        }

        .rs-col.company-info {
            p {
                margin-bottom: 5px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                overflow: hidden;
                display: -webkit-box;
                min-height: 47.98px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 50px;
            }
        }

        .rs-stack {
            width: 100%;
        }

        .rs-stack-item .industry-name {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .rs-btn-icon {
            width: 100%;
            padding: 16px 8px;
        }

        .rs-btn-icon:disabled {
            background: #E4E4E4;
            color: #ABABAB;
            opacity: 0.7;
        }

        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }

        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;

                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }

        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }

        .rs-badge {
            border-radius: 20px;
            padding: 2px 10px;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }
    }
}

.search-job-categories-title {
    h1 {
        font-size: 32px;
        margin-left: 8px;
        line-height: 35.2px;
        margin-bottom: 36px;
        text-transform: capitalize;
        text-align: left !important;
    }

    @media screen and (max-width: 768px) {
        h1 {
            margin-left: 8px;
            margin-bottom: 24px;
        }
    }
}

.search-job-categories-container {
    @media screen and (min-width: 1024px) {
        width: 120%;
    }
}

#search-job-categories {
    padding-top: 8px;

    .rs-panel {
        min-height: 20vh;
        max-height: 120px;
        border-radius: 25px;
        cursor: pointer;

        h5 {
            position: absolute;
            top: 1.25rem;
            color: white;
            font-weight: 700;
            font-size: 100%;
            width: 70%;
        }

        .rs-btn-icon {
            position: absolute;
            top: 0.75rem;
            right: 8px;
            color: #F6F6F6;
        }

        .rs-btn-icon:hover {
            background-color: transparent;
        }
    }

    .rs-col {
        .rs-panel {
            margin-left: 8px;
            background-image: url('/images/bg-job-categories-2.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    @media screen  and (max-width: 768px) {
        padding-left: 0px !important;

        .rs-col {
            margin-top: 2em;

            .rs-panel {
                margin-left: 0px !important;
            }
        }
    }

    @media screen  and (max-width: 425px) {
        padding-left: 0px !important;

        .rs-col {
            margin-top: 2em;

            .rs-panel {
                margin-left: 0px !important;
            }
        }

        .rs-col:first-child {
            margin-top: 0px;
        }
    }
}