.sidebar {
    height: 100%;
    padding: 10vh 0px;
    right: 0;
    top: 0;
    width: 20%;
    position: fixed!important;
    z-index: 100;
    overflow:auto;
    overflow-x: hidden;
}

.sidebar-close-btn {
    top: 2vh;
    right: 3.5%;
    height: 48px;
    width: 48px;
    color: #004AAD;
    font-weight: 700 !important;
    background-color: #E4F0FF !important;
    border-radius: 50%;
    position: fixed!important;
}

.sidebar-logo {
    top: 2vh;
    left: 6.25%;
    height: 40px;
    color: #004AAD;
    font-weight: 700 !important;
    background-color: #E4F0FF !important;
    border-radius: 50%;
    position: fixed!important;
}

.animate-top{
    position: relative;
    animation: animatetop 0.25s 1 linear both;
}

.animate-top-hide{
    position: relative;
    animation: animatetophide 0.25s 1 linear both;
}

.bar-block .bar-item{
    width: 100%;
    color: white;
    display: block;
    padding: 8px 16px;
    text-align: left;
    border:none;
    white-space:normal;
    float:none;
    outline:0;

    .rs-avatar {
        border-radius: 0%;
        img[alt="icon-sidebar"] {
            object-fit: contain;
            width: 100%;
            height: 32px;
            padding: 12px;
            margin-top: 12px;
            padding-bottom: 0px;
        }
    }

    p {
        display: inline-block;
        margin-top: -12px;
    }
}

.sidebar-btn {
    width: 90%;
    margin-left: 5%;
    height: 56px;
    font-weight: 400 !important;
    border-radius: 50px !important;
}

.sidebar-btn:last-child {
    margin-top: 20px;
}

.sidebar-btn#sb-signup-btn {
    background-color: #E4F0FF;
    color: #004AAD;
}

.sidebar-btn#sb-login-btn {
    background-color: #004AAD;
    color: white;
}

@keyframes animatetop{
    from{
        transform: scale(0);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes animatetophide{
    from{
        transform: scale(1);
        opacity: 1;
    }
    to{
        transform: scale(0);
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    .sidebar {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .sidebar {
        .menu-container {
            width: 100%;
            height: 200px;

            a {
                height: 35%;
                // padding: 6.5%;
                // padding: 2.5% !important;
                color: black;
                font-size: large;
                position: relative;

                div {
                    
                    img {
                        padding: 5px !important;
                        margin-top:0px !important;
                    }
                }

                .menu-container__p {
                    position: absolute;
                    top: 45%;
                }
            }
        }

        filter: blur(0px);
        -webkit-filter: blur(0px);
        background-color: white;
    }

    .bg-white-active {
        animation: animatechangebg 0.25s 1 linear both;
    }

    @keyframes animatechangebg{
        // from{
        //     filter: blur(0px);
        //     -webkit-filter: blur(0px);
        // }
        // to{
        //     filter: blur(50px);
        //     -webkit-filter: blur(50px);
        // }

        from {
            background-color: transparent;
        }

        to {
            background-color: white;
        }
    }

    .bg-white-hide {
        animation: animatechangebghide 0.25s 1 linear both;
    }

    @keyframes animatechangebghide{
        // from{
        //     filter: blur(50px);
        //     -webkit-filter: blur(50px);
        // }
        // to{
        //     filter: blur(0px);
        //     -webkit-filter: blur(0px);
        // }
        from {
            background-color: white;
        }

        to {
            background-color: transparent;
        }
    }
}