.user-home-hiring-partner-main-content {
    h1 {
        text-align: left !important;
        line-height: 2.2em;
        font-size: 2em;
        text-transform: capitalize;
    }

    >.rs-col {
        margin-bottom: 2.5em !important;
    }

    .rs-placeholder-graph {
        border-radius: 1.25em;
        margin-bottom: 1.5em;
        min-height: 24.375em;
    }

    .rs-placeholder-graph.loader-category {
        height: 7.5em !important;
        min-height: 20vh;
        max-height: 7.5em;
    }

    .search-results-row {
        margin-bottom: 1.5em;

        >.rs-col {
            padding-left: 0.75em;
            padding-right: 0.75em;
        }

        @media screen and (max-width: 48em) {
            margin-bottom: 0em;
            >.rs-col {
                margin-bottom: 1.5em;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
            object-fit: contain;
        }
    }

    .add-new-job {
        margin-top: 8px;

        @media screen and (min-width: 1024px) {
            svg {
                padding-top: 4px;
            } 
        }
    }

    .rs-input-group-addon,.rs-input-group-addon:hover {
        margin-top: 0.5625em;
        color: #3F77C2 !important;
        background-color: transparent !important;
    }

    .rs-input,.rs-picker-toggle,.rs-picker-tag {
        padding: 1.09375em 1.25em !important;
        padding-left: 2.25em !important;
        min-height: 3.375em;
    }

    .rs-picker-tag {
        min-height: 3.625em;
        color: #212121;
        padding: 0.5625em 1.25em !important;

        .rs-picker-toggle {
            min-height: max-content;
            padding: 0.5625em !important;
            padding-left: 1.09375em !important;
        }
    }

    .rs-picker-select,.rs-picker-tag {
        width: 100%;
    }

    .loading-skeleton>.rs-stack-item {
        width: 100%;
    }

    .myoverview-panel {
        color: white;
        border-radius: 20px;
        min-height: 162px;
        transition: 0.4s;

        &:hover {
            box-shadow: 0 0.3125em 0.625em rgb(0 0 0 / 10%);
        }

        p {
            font-weight: 600;
            font-size: 0.5em !important;
            min-height: 44.8px;
        }

        b {
            font-size: 1.25em;
        }

        small {
            color: white;
            display: block;
            font-size: 0.45em;
        }
    }

    .job-list-panel {
        background-color: #ffffff;
        border: 1px solid #E4E4E4;
        border-radius: 20px;

        h2 {
            font-size: 1.35em;
        }

        .rs-stack-item {
            width: 100% !important;
        }

        .job-list-search-results {
            background: white;
            border-radius: 12px;
            color: #212121;
            cursor: pointer;

            p {
                font-size: 0.9em;
                font-weight: 600;
            }

            small {
                font-size: 0.8em;
            }

            &:hover,&.active {
                color: #004AAD;
                background: #F2F7FF;
            }
        }
    }

    @media screen and (max-width: 425px) {
        .myoverview-panel#accepted-phase {
            margin-top: 16px !important;
        }   

        .myoverview-panel#rejected-phase {
            margin-top: 16px !important;
        }   

    }

    .myoverview-panel#job-applied{
        background-color: #D341FF;
    }

    .myoverview-panel#screening-phase{
        background-color: #FFB800;
    }

    .myoverview-panel#need-proceed-phase {
        background-color: #71ACFF;
    }
    
    .myoverview-panel#interview-phase {
        background-color: #003884;
    }

    .myoverview-panel#accepted-phase {
        background-color: #05BE17;
    }

    .myoverview-panel#rejected-phase {
        background-color: #FA0000;
    }

    .search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        border-radius: 1.25em;
        padding: 1em;
        max-height: 30em;
        transition: 0.4s;
    
        &:hover {
            box-shadow: 0 0.3125em 0.625em rgb(0 0 0 / 10%);
        }

        svg {
            color: var(--rs-primary-500);
        }

        p {
            color: #595959;
            line-height: 1.2em;
            font-size: 100%;
        }

        p.salary-range {
            color: #212121;
            line-height: 1.4em;
            font-weight: 700;
            font-size: 110%;
        }

        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;

                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }
        }

        .rs-col.company-info {
            p {
                margin-bottom: 0.3125em;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                min-height: 3.125em;
            }
        }

        .rs-stack {
            width: 100%;
        }

        .rs-btn-icon {
            width: 100%;
            padding: 1em 0.5em;
        }

        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }

        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;

                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }

        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }

        .rs-badge {
            border-radius: 1.25em;
            padding: 0.125em 0.625em;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }
    }

    .action-btn-group {
        margin-top: -8px;

        .action-btn {
            background-color: transparent;
            border: 1px solid #E4E4E4;
            border-radius: 4px;

            &.comments {
                color: #004AAD;
            }

            &.check {
                color: #05BE17;
            }

            &.times {
                color: #FA0000;
            }

            &:hover {
                background-color: #E4E4E4;
            }
        }
    }

    // Panel - filter
    .filter-panel {
        padding: 20px;
        padding-top: 24px;
        background-color: white !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;

        p {
            font-size: 1em;
            color: #595959;
        }

        .job-title {
            font-size: 1.5em;
            color: #212121;

            @media screen and (max-width: 425px) {
                font-size: 1.25em;
            }
        }

        .filter-sort-btn {
            float: right;

            img {
                margin-left: 8px;
                max-width: 18px;
                max-height: 18px;

                @media screen and (max-width: 425px) {
                    margin-left: 0px;
                }
            }
        }

        .filter-sort-btn.rs-btn {
            border-radius: 10px;
            color: #004AAD;
            padding: 12px;
            padding-left: 24px;
            padding-right: 24px;
            font-size: 1.1em !important;
            float: right;
            background: #E4F0FF;
        }
    }

    // Table css
    .rs-table-body-row-wrapper {
        background-color: white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .rs-table-row-header {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    
    .rs-table-cell-header>div[role="columnheader"] {
    
        .rs-table-cell-content {
            text-align: left !important;
            font-size: 1.25em;
            color: #212121;
            font-weight: 600;
        }
    }

    .rs-table-cell-header>div[role="columnheader"] {
        &[aria-colindex="3"]>.rs-table-cell-content {
            text-align: center !important;
        }
    }
    
    .rs-table-row {
        text-align: center;
    
        .rs-table-cell-content {
            color: #212121;
            padding: 28px 18px;
            text-align: left !important;
            white-space: inherit;

            p {
                background-color: #90BFFF;
                white-space: break-spaces !important;
                margin-top: -6px;
                padding: 6px;
                font-size: 1em;
                text-align: center !important;
                color: #004AAD;
                background: #F2F7FF;
                border-radius: 4px !important;
            }
        }
    
        .rs-avatar {
            background-color: white;
            object-fit: contain;
        }
    
        .rs-table-cell-last {
            .rs-table-cell-content {
                padding-right: 20%;
            }
        }
    
        &[role="row"] {
            cursor: pointer;
        }
    
        &[role="row"].rs-table-row-header {
            cursor: default;
        }
    }

    .rs-pagination-group {
        padding-top: 24px;

        .rs-pagination-group-total,.rs-pagination-btn {
            color: #595959;
            margin-left: 1em;
            
            span {
                font-size: 1em;
            }
        }

        .rs-pagination {
            button {
                font-size: 1em !important;
            }
        }
    }

    .job-picker {
        .rs-picker-toggle,&.rs-picker-has-value.rs-picker-toggle-wrapper:not(:focus) {
            border: none !important;
            padding: 0px !important;
            padding-top: 4px !important;
            padding-bottom: 4px !important;

            span {
                white-space: break-spaces !important;
            }
        }

        .rs-picker-toggle {
            .rs-btn-close {
                display: none;
            }
        }

        .rs-picker-toggle-value {
            color: #004AAD !important;
        }
    }
}

.rs-checkbox-group.filter-sort-cg {
    label {
        font-size: 1em;
    }
}

.rs-modal-content {
    .rs-modal-body {
        text-align: center;

        img.bg-interview-modal-hp {
            max-height: 250px;
            margin-bottom: 1.3rem;
        }

        .upload-offer-section {
            padding-top: 1em;

            h5 {
                color: black;
                font-weight: 600;
                text-align: left;
                font-size: 1em;
            }

            p {
                color: black;
                font-size: .9em;
                text-align: justify;
            }
            
            p.description {
                color: var(--rs-text-primary);
                font-size: .8em;
                margin-bottom: 1em;

                >a {
                    font-size: 1em;
                }
            }
        }
    }
}

.hp-modal {
    .rs-modal-content {
        margin-top: 0px !important;
    
        .rs-checkbox-group {
            margin-top: 8px;
        }

        .rs-radio label {
            font-size: 1em !important;
        }
    
        .tg-checkbox-group-btn {
            margin-top: 12px;
            
            .tg-checkbox-btn {
                border: 1px solid #004AAD;
                border-radius: 48px;
        
                .rs-checkbox-wrapper .rs-checkbox-inner:before {
                    border: 1px solid white !important;
                }
            }
        }
    
        .rs-modal-body {
            text-align: left;
    
            .rs-uploader-text, .rs-uploader-picture {
                display: block;
    
                .rs-uploader-trigger-btn {
                    cursor: default;
                }
    
                svg {
                    width: 1.75em;
                    color: #757575;
                    height: 1.75em;
                    margin-top: 1.5em;
    
                    &.download-icon,&.pencil-edit-icon {
                        width: 1.4em;
                        height: 1.4em;
                        cursor: pointer;
                        color: #ABABAB;
                    }
                }
            }
    
            .rs-uploader-trigger-customize {
                width: 100%;
    
                .rs-uploader-trigger-btn {
                    width: 100%;
                }
            }
    
            .rs-form-control-label {
                font-size: 1em;
                font-weight: 600;
            }
    
            .rs-col-xs-8 {
                padding-top: 12px !important;
            }
    
            .quill {
                margin-top: 8px;
    
                p {
                    text-align: left !important;
                }
            }
    
            img.bg-interview-modal-hp {
                max-height: 250px;
                margin-bottom: 1.3rem;
            }
        }
    }
}