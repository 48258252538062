.home-static-container,.home-static-authorized-container {
    background-image: white;
    color: rgb(226, 226, 226);
    padding-top: 5rem !important;
    padding-bottom: 4.5rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;

    :first-child{
        .home-static-item-container:first-child {
            padding-left: 6vw !important;
        }

        .home-static-item-container:last-child {
            padding-left: 6vw !important;
            padding-right: 5vw !important;
        }

        .home-static-item-container:only-child {
            padding-left: 6vw !important;
            padding-right: 6vw !important;
        }

        @media screen and (max-width: 425px) {
            .home-static-item-container:last-child,.home-static-item-container:first-child {
                padding-left: 3rem !important;
                padding-right: 3rem !important;
            }
        }
    }

    h1 {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
    }

    .title-desc {
        text-align: center;
        margin-bottom: 2.5rem;
        margin-top: 11px;
        color: #595959;
    }

    .title-desc#job-categories {
        text-align: center;
        margin-bottom: 2.5rem;
        margin-top: 2rem;
        color: #595959;
    }

    .slider {
        min-height: 480px;

        .slider-item-avatar {
            width: 134px;
            height: 134px;
            position: absolute;
            top: -64px;
            right: 33.33%;
            z-index: 98;
            border-radius: 50%;
        }

        div#hiring-partner {
            >div.rs-col:first-child {
                >.slider-item-avatar {
                    border: 6px solid #FFB800 !important;
                    background-color: white;
                }
            }

            >div.rs-col:nth-child(3) {
                >.slider-item-avatar {
                    border: 6px solid #D341FF !important;
                    background-color: white;
                }

                >.slider-item-avatar[alt="avatar-maxi"] {
                    border: 0px solid transparent !important;
                }
            }
        }

        .rs-panel {
            border-radius: 30px;
            transition: all .35s ease-in-out;

            .rs-panel-body {
                padding-top: 84px;
            }

            p {
                color: #595959;
                text-align: center;
                font-weight: 400;
                font-size: 0.9em;
            }

            h5 {
                font-size: 1.2em;
                margin-top: 24px;
                text-align: center;
                color: #212121;
                font-weight: 700;
            }

            h5+p {
                font-weight: 600;
                color: #595959;
            }
        }

        .rs-panel#hiring-partner {
            .rs-panel-body {
                position: relative;
                min-height: 356px;
                padding-top: 33.33%;

                h5 {
                    position: absolute;
                    bottom: 10%;
                    width: 100%;
                    margin-left: -20px;
                    text-align: center;
                }

                h5+p {
                    position: absolute;
                    bottom: 3.5%;
                    width: 100%;
                    margin-left: -20px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    max-height: 255ch;
                    -webkit-line-clamp: 8; /* number of lines to show */
                    line-clamp: 8; 
                    -webkit-box-orient: vertical;
                }
            }
        }

        .rs-panel#why-should-hire-us {
            .rs-panel-body {
                padding-top: 28%;

                @media screen and (max-width: 425px) {
                    padding-top: 33%;
                }

                h5,p {
                    text-align: left;
                }
            }
        }

        .rs-panel:hover {
            transform: scale(1.03);
        }

        .rs-row {
            padding-top: 64px;
            padding-bottom: 64px;

            .rs-col:nth-child(2)>.rs-panel {
                background: #E4F0FF;
            }
            .rs-col:last-child>.rs-panel {
                background-color: #FAE8FF;
            }
            .rs-col:first-child>.rs-panel {
                background-color: #FFF7D7;
            }
        }

        .rs-row#hiring-partner {
            padding-top: 64px;
            padding-bottom: 64px;

            .rs-col:first-child>.rs-panel {
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-yellow.png');
            }
            .rs-col:last-child>.rs-panel {
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-pink.png');
            }
        }

        .rs-row#reason-hiring-partner {
            padding-top: 56px;
            padding-bottom: 56px;

            img {
                position: absolute;
                top: 5%;
                width: 72px;
                height: 82px;
            }

            .rs-col:nth-child(2)>.rs-panel {
                height: 270px;
                margin-left: 24px;
                background: #C3DCFF;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-blue-bold.png');
            }
            .rs-col:last-child>.rs-panel {
                height: 270px;
                margin-left: 24px;
                background: #FFEDA5;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-yellow-bold.png');
            }
            .rs-col:first-child>.rs-panel {
                height: 270px;
                background: #F7DBFF;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-pink-bold.png');
            }
        }

        .rs-row#hiring-steps {
            padding-top: 0rem;
            padding-bottom: 1rem;

            .rs-panel-body {
                height: 100%;
                padding-top: 24px;
                padding-bottom: 24px;
            }

            .rs-panel {
                min-height: 465px;
                background-color: #004AAD;

                img {
                    width: 100%;
                }

                h5,p {
                    text-align: left;
                    font-weight: 400;
                    color: white !important;
                }

                h5 {
                    font-weight: 700;
                }

                p {
                    text-align: justify;
                }
            }
        }

        .rs-row#hiring-steps:first-child {
            padding-top: 5rem;

            .rs-col:first-child {
                .rs-panel {
                    background-image: url('/images/bg-number-1.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            .rs-col:last-child {
                .rs-panel {
                    background-image: url('/images/bg-number-2.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }

        .rs-row#hiring-steps:last-child {
            .rs-col:first-child {
                .rs-panel {
                    background-image: url('/images/bg-number-3.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

            .rs-col:last-child {
                .rs-panel {
                    background-image: url('/images/bg-number-4.png');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }

        .rs-carousel-slider {
            background-color: white;

            .rs-carousel-slider-item {
                background-color: white;
            }
        }

        .rs-carousel-slider-after {
            background-color: white;

            .rs-carousel-slider-item {
                background-color: white;
            }
        }

        // slider - dot shaped checked
        .rs-carousel-label-wrapper input:checked ~ label {
            width: 16px;     
            height: 16px;            
        }

        // slider- dot shaped not checked
        .rs-carousel-label-wrapper input:not(:checked) ~ label {
            background-color: #C2D4EB;
        }

        
    }

    .home-static-item-container {
        padding-left: 16px;
        padding-right: 16px;
        // padding-top: 2.75rem;
        padding-bottom: 2.75rem;
        border-radius: 50px;
        background-color: #004AAD;
        transition: all .35s ease-in-out;

        &:last-child {
            padding-top: 2.75em;
        }

        div {
            position: relative;
            text-align: center;

            >p {
                color: #FFDE59;
                font-weight: 700;
                margin-bottom: 24px;
                z-index: 1;
                position: relative;
            }

            >button {
                background: #FFFFFF;
                margin-top: 36px;
                font-weight: 700;
                padding: 30px;
                padding-top: 16px;
                padding-bottom: 16px;
                color: #212121;
                border-radius: 50px;
                transition: all .35s ease-in-out;
            }

            >button:hover {
                transform: scale(1.1);
            }

            button+img {
                position: absolute;
                width: 97px;
                left: 27.5%;
                margin-top: -6.5%;
                z-index: 0;
            }

            @media screen and (max-width: 425px) {
                button+img {
                    margin-top: -12% !important;
                    left: 37.5%;
                    z-index: 1;
                }
            }

            @media screen and (max-width: 400px) {
                button+img {
                    left: 44.5%;
                }
            }
    
            >h5 {
                color: white;
                text-transform: uppercase;
                font-size: 1.8em;
                font-weight: 700;
                line-height: 30px;
            }
        }

        #meeting-hiring-partners {
            padding-top: 2.75rem;

            p {
                color: #595959;
                text-align: center;
                font-weight: 400;
                // font-size: 0.9em;
            }

            h1 {
                position: relative;
                z-index: 1;
            }

            img.white-star {
                position: absolute;
                width: 64px;
                top: 7%;
                right: 3.5%;
                z-index: 0;
            }

            img.yellow-paint {
                position: absolute;
                width: 128px;
                top: 7%;
                left: 2.5%;
                z-index: 0;
            }
        }

        .rs-avatar-group {
            margin-top: 24px;
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            gap: 4.5em;

            .rs-avatar:hover {
                transform: scale(1.05);
                cursor: pointer;
            }
        }

        @media screen and (max-width: 425px) {
            .rs-avatar-group {
                gap: 2em;
            }
        }
    }

    .home-static-item-container:first-child {
        background-image: url('/images/bg-image-hire-button.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .home-static-item-container#hiring-partner:first-child {
        background-image: url('/images/bg-image-hire-button-2.png');
        background-size: cover;
        background-repeat: no-repeat;
    }

    &.reason-hiring-partner-container {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }

    @media screen and (max-width: 425px) {
        &.reason-hiring-partner-container {
            padding-left: 3% !important;
            padding-right: 3% !important;
        }
    }
}

.home-static-authorized-container {
    .rs-divider {
        margin-top: 12px;
        margin-bottom: 18px;
    }

    @media screen and (max-width: 425px) {
        .company-details-side-card {
            margin-top: 24px;
        }
    }
}

#tutorial-item-container {
    background-image: none;
    background-color: white;
    padding-top: 7.5rem;
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    h1 {
        float: left;
        width: 100%;
        text-align: left;
        line-height: 0.95em;
        margin-bottom: 1em;

        img.decoration{
            position: absolute;
            width: 85%;
            top: 7%;
            z-index: 0;
        }
    }

    .rs-col {
        margin-top: 8px;
    }

    .rs-col:first-child {
        img {
            margin-top: -13vh;
        }
    }

    .rs-panel {
        height: 284px;
        padding: 16px;
        border-radius: 25px;
        background-color: #FFDE59;
        transition: 0.4s all ease-in-out;

        .rs-panel-body {
            padding-top: 0px;

            .rs-avatar {
                font-weight: 700;
                float: left;
                background-color: #004AAD;
                margin-right: 100%;
                margin-bottom: 16px;
            }
        }

        h5 {
            color: black;
            font-weight: 700;
            text-align: left;
            margin-bottom: 12px;
            font-size: 1.4em;
            text-transform: unset;
        }

        p {
            text-align: justify;
            color: #595959;
            font-weight: 200;
            line-height: 20px;
        }
    }

    .rs-panel:hover {
        transform: scale(0.985);
        background-color: #004AAD;

        .rs-avatar {
            color: black;
            background-color: #FFDE59;
        }

        h5 {
            color: white;
        }

        p {
            color: #E4F0FF;
        }
    }
}

#candidate-reason-join {
    background-image: url('/images/bg-reason-join-section.png');
    background-repeat: no-repeat;
    background-size: contain;

    padding-left: 7.5% !important;

    h1 {
        position: relative;
        z-index: 1;

        >img:first-child {
            position: absolute;
            width: 45px;
            top: 0%;
            left: 15%;
            z-index: -1;
        }

        >img:last-child {
            position: absolute;
            width: 92px;
            top: -15%;
            right: 17.5%;
            z-index: -1;
        }
    }

    .rs-panel {
        transition: all .3s ease-in;
        border-radius: 25px;
        width: 80%;
        h5 {
            color: #212121;
            font-weight: 700;
        }

        p {
            color: #595959;
            font-weight: 400;
            font-size: 14px;
        }
    }

    .rs-col {
        .line-divider.rs-stack-item {
            width: 100%;
            left: 35%;
            position: relative;
    
            .rs-divider.rs-divider-vertical {
                background-color: #EA9FFF;
                width: 16px;
                height: 48px;
                z-index: -1;
            }
        }
    }

    .rs-col:nth-child(1) {
        margin-top: 10vh;
        .rs-stack-item:nth-child(1) {
            .rs-panel {
                padding-top: 12vh;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-pink-bold.png');
            }
        }

        .rs-panel {
            background-color: #FCF4FF;
        }
    }

    .rs-col:nth-child(2) {
        .rs-stack-item:nth-child(1) {
            .rs-panel {
                padding-top: 12vh;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-blue-bold.png');
            }
        }
        .rs-panel {
            background: #E4F0FF;
        }
        .rs-divider.rs-divider-vertical {
            background-color: #90BFFF !important;
        }
    }

    .rs-col:nth-child(3) {
        margin-top: 10vh;
        .rs-stack-item:nth-child(1) {
            .rs-panel {
                padding-top: 12vh;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('/images/bg-hiring-partner-testimony-yellow-bold.png');
            }
        }
        .rs-panel {
            background: #FFF7D7;
        }
        .rs-divider.rs-divider-vertical {
            background-color: #FFB800 !important;
        }
    }

    #candidate-item-reason-join-us {
        margin-top: 8px !important;

        img {
            width: 50px
        }
    }
}

#candidate-testimony {
    padding-right: 0px !important;
    padding-left: 0px !important;
    position: relative;

    img.yellow-paint {
        position: absolute;
        width: 128px;
        z-index: 0;
        margin-top: -2em;
        left: 5%;
    }

    @media screen and (min-width: 1025px) {
        img.prev-btn {
            left: -10%;
            top: 57.5%;
        }
        img.next-btn {
            right: -10%;
            top: 57.5%;
        }
    }

    h1 {
        position: relative;
        z-index: 1;
    }

    .rs-col {
        >.rs-panel {
            height: 360px;
            margin-right: 24px;

            .rs-panel-body {
                height: 100%;
                position: relative;

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    max-height: 255ch;
                    -webkit-line-clamp: 8; /* number of lines to show */
                    line-clamp: 8; 
                    -webkit-box-orient: vertical;
                }
                
                .job-title-container {
                    position: absolute;
                    bottom: 7.5%;
                    left: 5%;
                    text-align: center;
                    width: 90%;

                    p {
                        margin-left: 10%;
                        width: 80%;
                    }
                }
            }
        }

        &:first-child {
            .rs-panel-body {
                background-image: url('/images/bg-spiral-yellow.png');
                background-repeat: no-repeat;
                background-size: contain;
            }

            .slider-item-avatar {
                border: 6px solid #FFB800 !important;
            }
        }

        &:nth-child(2) {
            .rs-panel-body {
                background-image: url('/images/bg-spiral-blue.png');
                background-repeat: no-repeat;
                background-size: contain;
            }

            .slider-item-avatar {
                border: 6px solid #70ACFF !important;
            }
        }

        &:nth-child(3) {
            .rs-panel-body {
                background-image: url('/images/bg-spiral-pink.png');
                background-repeat: no-repeat;
                background-size:cover;
            }

            .slider-item-avatar {
                border: 6px solid #D341FF !important;
            }
        }
    }
}

#hiring-home {
    background-image: url('/images/bg-hiring-home.png');
    padding-top: 0rem !important;
    padding-bottom: 2rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    min-height: 92vh;
    background-size: cover;

    .home-static-item-container {
        background-color: transparent;
        background-image: none;
        padding-left: 10vw;

        h1 {
            margin-top: 15vh;
            color: white;
            text-align: left;
            font-weight: bolder;

            div {
                text-align: left;
                color: #004AAD;
                width: fit-content;
                padding-right: 8px;
                padding-left: 8px;
                display: inline-block;
                margin-bottom: 10px;
                background-color: #FFB800;
            }

            @media screen and (min-width: 1024px) {
                line-height: 120%;
            }

            @media screen and (max-width: 768px) {
                div {
                    margin-bottom: 4px;
                }
            }
        }

        p {
            margin-top: 3rem;
            text-align: left;
            color: white;
            font-weight: 200;
            line-height: 24px;
            opacity: 0.96;
        }

        button {
            margin-top: .5rem;
            float: left;
        }

        img {
            margin-top: -1rem;
            width: 140%;
            margin-left: -25%;
        }

        div {
            >button+img {
                position: absolute;
                width: 97px !important;
                left: 62.5%;
                margin-top: -6.5%;
                z-index: 0;
            }

            @media screen and (max-width: 425px) {
                button+img {
                    margin-top: -12% !important;
                    right: 30%;
                    z-index: 1;
                }
            }

            @media screen and (max-width: 400px) {
                button+img {
                    right: -2.5% !important;
                    left: unset !important;
                }
            }
        }
    }
}

#top-candidate-home {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    min-height: 92vh;
    background-image: url('/images/bg-home.png');
    background-size: cover;

    .home-static-item-container {
        background-color: transparent;
        background-image: none;
        padding-left: 10vw;

        h1 {
            margin-top: 12vh;
            color: white;
            text-align: left;
            font-weight: bolder;

            div {
                text-align: left;
                color: #004AAD;
                width: fit-content;
                padding-right: 8px;
                padding-left: 8px;
                margin-top: 8px;
                background-color: #FFB800;
            }

            @media screen and (min-width: 1024px) {
                line-height: 120%;
            }

            @media screen and (max-width: 768px) {
                div {
                    margin-top: 0px;
                }
            }
        }

        p {
            margin-top: 3rem;
            text-align: left;
            color: white;
            font-weight: 200;
            line-height: 24px;
            opacity: 0.96;
        }

        button {
            margin-top: .5rem;
            float: left;
            z-index: 1;
        }
    }
}

#job-categories {
    .rs-panel {
        height: 360px;
        border-radius: 25px;

        h5 {
            position: absolute;
            bottom: 5.25rem;
            color: white;
            font-weight: 700;
        }

        button {
            position: absolute;
            bottom: 1.75rem;
            font-weight: 600;
            font-size: 0.9em !important;
            padding: 12px 16px;
            border-radius: 25px;
            color: #212121;
        }

        button:hover,button:focus {
            color: black
        }
    }

    .rs-col:first-child {
        .rs-panel {
            margin-left: 24px;
            background-image: url('/images/bg-job-categories-1.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .rs-col:nth-child(2) {
        .rs-panel {
            margin-left: 24px;
            background-image: url('/images/bg-job-categories-2.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .rs-col:last-child {
        .rs-panel {
            margin-left: 24px;
            background-image: url('/images/bg-job-categories-3.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.discover-career-container {
    background: #FFDE59;
    width: 100% !important;

    h1 {
        margin-bottom: 8px;
    }

    a {
        text-align: center;
        color: #595959;
        span {
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;
            span {
                color: #212121;
            }

            svg {
                color: #212121;
            }
        }
    }

    .search-results-row {
        margin-bottom: 24px;

        >.rs-col {
            padding-left: 12px;
            padding-right: 12px;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            >.rs-col {
                margin-bottom: 24px;
            }
        }

        .rs-avatar-image {
            object-fit: contain;
            background-color: white;
            object-fit: contain;
        }
    }

    .rs-placeholder-graph {
        border-radius: 20px;
        margin-bottom: 24px;
        min-height: 27.5em;
    }

    .search-result-card {
        background-color: #FFFFFF;
        color: #E4E4E4;
        border-radius: 20px;
        padding: 16px;
        max-height: 30em;
        transition: 0.4s;
    
        &:hover {
            box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
        }

        svg {
            color: var(--rs-primary-500);
        }

        p {
            color: #595959;
            line-height: 19.2px;
            font-size: 100%;
        }

        p.salary-range {
            color: #212121;
            line-height: 22.4px;
            font-weight: 700;
            font-size: 110%;
        }

        .rs-stack {
            >.rs-stack-item:last-child,>.rs-stack-item:nth-child(2),>.rs-stack-item:nth-child(3) {
                width: 100% !important;

                .rs-stack,.rs-stack-item {
                    width: 100% !important;
                }
            }

            .rs-stack-item .industry-name {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .rs-col.company-info {
            p {
                margin-bottom: 5px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            b {
                font-size: 1.2em;
                color: black;
                font-weight: 700;
                overflow: hidden;
                display: -webkit-box;
                min-height: 47.98px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .rs-stack {
            width: 100%;
        }

        .rs-btn-icon {
            width: 100%;
            padding: 16px 8px;
        }

        .rs-stack-item .rs-stack-item:first-child {
            .rs-badge {
                background-color: #E9A0FF;
            }
        }

        .rs-stack-item .rs-stack-item:nth-child(2) {
            .rs-badge {
                background-color: #FFDE59;

                &.rs-badge-fulltime {
                    background-color: #003884 !important;
                    color: #ffffff !important;
                }
            }
        }

        .rs-stack-item .rs-stack-item:last-child {
            .rs-badge {
                background-color: #90BFFF;
            }
        }

        .rs-badge {
            border-radius: 20px;
            padding: 2px 10px;
            font-weight: 400;
            white-space: nowrap;
            color: #212121;
        }
    }
}

.top-home {
    img {
        width: 100%;
        margin-top: 3rem;
    }

    .rs-col:nth-child(3), .rs-col:nth-child(4) {
        img {
            margin-top: 0;
        }
    }
}

img.prev-btn,img.next-btn {
    position: absolute;
    width: 38px;
    cursor: pointer;
    z-index: 1;
}

img.prev-btn {
    left: 0%;
    top: 57.5%;
}
img.next-btn {
    right: 0%;
    top: 57.5%;
}

@media screen and (min-width: 1024px) {
    .home-static-container,.home-static-authorized-container {
        &.meeting-hiring-partners-container {
            padding-left: 10% !important;
            padding-right: 10% !important;
        }
    }
}

@media screen and (width: 1024px) {
    #tutorial-item-container {
        .rs-panel {
            height: 300px;
        }
    }
}

@media screen and (max-width: 820px) {
    #candidate-reason-join {
        padding-left: 5% !important;
        padding-right: 5% !important;

        .rs-panel {
            width: 95%;
        }

        .rs-col {
            margin-bottom: 10vh !important;
        }

        .rs-stack-item:nth-child(1) {
            .rs-panel {
                padding-top: 5rem;
            }
        }


        #candidate-item-reason-join-us {
            margin-bottom: 0px !important;
        }
    }

    .home-static-container,.home-static-authorized-container {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    // .slider-item-avatar {
    //     right: 37.5% !important;
    // }

    #tutorial-item-container {
        padding-top: 2rem;
        padding-left: 16px !important;
        padding-right: 16px !important;

        h1 {
            margin-bottom: 16px;
            line-height: 110% !important;


            img.decoration{
                top: 2% !important;
            }
        }

        .rs-panel {
            height: 296px;
        }

        .rs-col {
            margin-top: 2rem;
        }

        .rs-col:first-child {
            img {
                margin-top: 0;
            }
        }
    }

    #tutorial-item-container:last-child {
        padding-top: 0;
    }

    .home-static-item-container:first-child {
        background-size: cover !important;
    }

    #top-candidate-home .home-static-item-container h1 {
        margin-top: 5vh;
    }

    #candidate-testimony {
        .rs-col {
            &:first-child {
                .slider-item-avatar {
                    border: 6px solid #FFB800 !important;
                }
            }

            &:nth-child(2) {
                .slider-item-avatar {
                    border: 6px solid #D341FF !important;
                }
            }
        }
    }

    .top-home {
        z-index: 0;
        display: block;
        img {
            width: 100%;
            margin-top: 5rem;
        }

        .rs-col {
            height: 360px
        }

        .rs-col:first-child,.rs-col:nth-child(2) {
            img {
                margin-top: 10rem;
            }
        }

        .rs-col:nth-child(3),.rs-col:nth-child(4) {
            img {
                margin-top: 5rem;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .slider {
        .rs-row#hiring-steps {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;

            .rs-col {
                margin-top: 1rem;
            }
        }

        .rs-row#hiring-steps:first-child {
            padding-top: 2rem !important;
        }

        .rs-row#hiring-steps {
            .rs-col:nth-child(2) {
                display: none;
            }
        }
    }

    #candidate-reason-join {
        .rs-panel {
            width: 100%;
        }

        .rs-stack-item {
            width: 100%;
            margin: auto;
        }

        .rs-stack-item:nth-child(1) {
            .rs-panel {
                padding-top: 7rem !important;
            }
        }
        // .rs-stack-item:nth-child(2),.rs-stack-item:nth-child(3) {
        //     .rs-panel {
        //         margin-top: 2rem;
        //     }
        // }

        .rs-col:nth-child(1),.rs-col:nth-child(2),.rs-col:nth-child(3) {
            margin-top: 0vh;
        }

        .rs-panel:hover {
            transform: scale(1);
        }

        #candidate-item-reason-join-us {
            img {
                width: 48px
            }
        }

        .line-divider.rs-stack-item {
            width: 100%;
            left: 43.33% !important;
            position: relative;
        }
    }

    #candidate-testimony {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    
        .rs-col {
            >.rs-panel {
                margin-right: 0px;
            }
        }
    }

    #tutorial-item-container {
        padding-top: 2rem;

        h1 {
            margin-bottom: 16px;
            line-height: 110% !important;
        }

        .rs-panel {
            height: auto;
        }

        .rs-col {
            margin-top: 2rem;
        }

        .rs-col:first-child {
            img {
                margin-top: 0;
            }
        }
    }

    #tutorial-item-container:last-child {
        padding-top: 0;
    }

    #top-candidate-home .home-static-item-container h1 {
        margin-top: 5vh;
    }

    #top-candidate-home {
        min-height: auto;
    }

    .top-home {
        margin-top: -16vh;
        padding: 0px !important;
    }

    #hiring-home {
        padding-bottom: 6rem !important;

        img {
            width: 100% !important;
            margin-top: 5rem !important;
            margin-left: 0% !important;
        }
    }

    #hiring-home .home-static-item-container:first-child {
        padding-top: 5vh;
        padding-bottom: 8vh;

        h1 {
            margin-top: 10vh;
        }
    }

    #top-candidate-home .home-static-item-container:first-child {
        padding-top: 4vh;
    }

    .reason-hiring-partner-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #reason-hiring-partner {
        &.rs-row {
            padding-top: 8px !important;
        }

        .rs-col {
            margin-bottom: 2.5rem;

            >.rs-panel {
                margin-left: 0px !important;
                margin-right: 0px !important;

                img {
                    position: absolute;
                    top: 10% !important;
                    width: 72px;
                    height: 82px;
                }
            }

            h5 {
                margin-top: 0px;
            }
        }
    }

    #job-categories {
        .rs-col {
            margin-bottom: 2rem;

            .rs-panel {
                margin-left: 0px !important;
            }
        }

        .rs-col:last-child {
            margin-bottom: 0;
        }
    }
}

.img-object-fit-cover {
    object-fit: cover;
}